<template>
  <div class="fill-height only-container">
    <va-aside-layout :title="asideTitle">
      <component
        :is="`${details.resource}-${details.action}`"
        :resource="details.resource"
        :item="details.item"
      >
      </component>
    </va-aside-layout>

    <v-card elevation="0" tile ref="main" class="fill-height only-container">
      <!-- <v-card-title>
        <h1 class="display-1">GRID - {{ title }}</h1>
      </v-card-title> -->
      <!-- <v-card-text class="overflow-y-auto fill-height pa-0"> -->
      <v-card-text class="fill-height pa-0">
        <!-- sheet with the same height as the toolbar, used for correct placement of
                next element -->
        <v-sheet :height="toolbarHeight" width="100%" style="opacity: 0" />
        <v-progress-linear
          v-if="loading"
          indeterminate
          color="orange"
          height="3px"
          style="z-index: 100"
        ></v-progress-linear>
        <v-sheet height="3px" width="100%" style="opacity: 0" v-else />
        <v-sheet :height="toolbarOffsetTop" width="100%" style="opacity: 0" />

        <!-- <v-sheet :height="toolbarHeight" width="100%"> </v-sheet> -->
        <!-- :height="
            $vuetify.breakpoint.height - toolbarHeight - containerOffset - 65
          " -->
        <v-sheet
          :height="
            $vuetify.breakpoint.height -
            toolbarHeight -
            toolbarOffsetTop -
            toolbarOffsetBottom -
            20
          "
          width="100%"
        >
          <!-- <va-list
            disableGlobalSearch
            @action="onAction"
            :query-params="{ flatten: 1 }"
            :filters="fields"
            class="fill-height"
          > -->
          <va-data-grid
            class="fill-height"
            :fields="fields"
            :editable="editMode"
          ></va-data-grid>
          <!-- :dense="true"
              @show-details="onAction" -->
          <!-- </va-list> -->
        </v-sheet>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { guessFields } from "../../utils/guesserSchema";

import Toolbar from "@/plugins/vuetify-admin/mixins/toolbar";

export default {
  mixins: [Toolbar],
  props: ["resource", "title"],
  name: "Grid",
  data() {
    return {
      fields: [],
      asideTitle: null,
      details: {},
      scrollListener: null,
    };
  },
  async created() {
    this.toolbarSetHeader({
      image: this.resource.background
        ? this.resource.background
        : "default.jpg",
      color: null,
      title: this.resource.pluralName,
      icon: this.resource.icon,
      reset: true,
    });
    this.fields = await guessFields(
      this.$store,
      this.$i18n,
      this.resource.name
    );

    if (this.$guessLogger) {
      this.$guessLogger(this.resource.name, "list", this.fields);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.toolbarUpdate({
        // update target when it is mounted
        scrollTarget: '[ref="eBodyViewport"]',
      });
    });
  },
  computed: {
    editMode() {
      //return this.$store.state[this.resource.name].mode.edit;
      return this.$store.state.api.mode.edit;
    },
    loading() {
      return this.$store.state.api.loading;
    },
  },
  methods: {
    async onAction({ action, title, id, item, reference }) {
      this.asideTitle = null;
      this.details.action = action;
      this.details.id = id;
      this.details.show = action === "show";
      this.details.item = item;
      this.details.resource = reference;
      this.asideTitle = title;
    },
  },
};
</script>

<style>
.only-container {
  height: 100%;
  background-color: #00000000 !important;
}
</style>