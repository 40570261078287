<template>
  <a v-if="value" :href="link"
    :target="target">{{ value }}</a>
</template>

<script>
import Field from "../../../../mixins/field";

/**
 * Show value as simple anchor.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Target value for anchor, default to external.
     */
    target: {
      type: String,
      default: "_blank",
    },
  },
  data() {
    return {
      base_link: "https://fdc.nal.usda.gov/fdc-app.html#/food-details/"
    };
  },
  computed: { 
      link() {
          return this.base_link + this.value + "/nutrients"
      }
  },
};
</script>
