<template>
  <div class="fill-height only-container">
    <!-- <va-aside-layout :title="asideTitle">
      <component
        :is="`${details.resource}-${details.action}`"
        :resource="details.resource"
        :item="details.item"
      >
      </component>
    </va-aside-layout> -->
    <!-- <v-sheet :height="toolbarHeight" width="100%" style="opacity: 0" /> -->
    <!-- <v-sheet
      id="scrollbar"
      :height="$vuetify.breakpoint.height - toolbarHeight - 100"
      width="100%"
      style="overflow-y: auto; overflow-x: hidden"
    > -->
    <va-show-layout :title="title" style="padding-top: 150px">
      <va-show :item="item">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
            <v-card
              :elevation="$vuetify.breakpoint.mdAndUp ? 6 : 0"
              shaped
              :color="$vuetify.breakpoint.mdAndUp ? '#eeff3' : undefined"
            >
              <v-card-text>
                <v-row>
                  <!-- ? 12 // multiline
                           : ....
                           ? 2 // color and numbers
                           : 3 // others -->
                  <v-col
                    v-for="field in fields_computed"
                    :key="field.source"
                    v-show="!field.hide"
                    :cols="
                      field.attributes.multiline || field.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(field.type)
                        ? 6
                        : 12
                    "
                    :sm="
                      field.attributes.multiline || field.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(field.type)
                        ? 4
                        : 6
                    "
                    :xl="
                      field.attributes.multiline
                        ? 12
                        : ('number', 'color').includes(field.type)
                        ? 2
                        : field.type.includes('array')
                        ? 6
                        : 3
                    "
                  >
                    <va-field
                      :source="field.source"
                      :label="field.label"
                      :type="field.type"
                      v-bind="field.attributes"
                      @show-details="showDetails"
                    ></va-field>
                  </v-col>
                </v-row>
                <!-- Created and modified fields -->
                <v-divider
                  inset
                  class="mt-2"
                  v-if="item && (item.created || item.modified)"
                />
                <div
                  style="font-size: smaller"
                  class="text--disabled text-right mt-2 mr-2"
                >
                  <va-datetime-by-field
                    v-if="item && item.created"
                    reference="users"
                    :item="item"
                    source="created_by"
                    item-text="username"
                    :fetch="true"
                    :label="$t('va.common.created_at')"
                    @show-details="showDetails"
                  ></va-datetime-by-field>
                  <br />
                  <va-datetime-by-field
                    v-if="
                      item && item.modified && item.created != item.modified
                    "
                    reference="users"
                    :item="item"
                    source="modified_by"
                    item-text="username"
                    :fetch="true"
                    :label="$t('va.common.modified_at')"
                    @show-details="showDetails"
                  ></va-datetime-by-field>
                </div>
                <!-- END Created and modified fields -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </va-show>
    </va-show-layout>
    <!-- </v-sheet> -->
  </div>
</template>

<script>
import { guessFields } from "../../utils/guesserSchema";
import Toolbar from "@/plugins/vuetify-admin/mixins/toolbar";

export default {
  mixins: [Toolbar],
  props: ["title", "resource", "item"],
  data() {
    return {
      fields: [],
      asideTitle: null,
      details: {},
      //toolbarHeight: 148,
    };
  },
  async created() {
    //this.$root.$on("toolbar-height", (val) => {
    //  this.toolbarHeight = val;
    //});
    this.fields = await guessFields(
      this.$store,
      this.$i18n,
      this.resource.name
    );

    if (this.$guessLogger) {
      this.$guessLogger(this.resource.name, "show", this.fields);
    }
    var toolbarTitle = this.title;
    if (this.fields[0].field === "name") {
      toolbarTitle = `#${this.item.id} - ${this.item.name}`;
    }
    this.toolbarUpdate({
      image: this.resource.background
        ? this.resource.background
        : "default.jpg",
      color: null,
      title: toolbarTitle,
      icon: this.resource.icon,
      shrink: true,
    });
  },
  mounted() {
    this.$nextTick(() => {
      this.toolbarUpdate({
        // update target when it is mounted
        scrollTarget: "#scrollbar .os-viewport", //#scrollbar",
        //scrollTarget: "#scrollbar",
      });
    });
  },
  computed: {
    fields_computed: function () {
      return this.fields.filter(
        (t) =>
          t && !(t.source.includes("created") || t.source.includes("modified"))
      );
    },
  },
  methods: {
    async showDetails({ action, title, id, item, reference }) {
      this.asideTitle = null;
      this.details.action = action;
      this.details.id = id;
      this.details.show = action === "show";
      this.details.item = item;
      this.details.resource = reference;
      this.asideTitle = title;
    },
  },
};
</script>
