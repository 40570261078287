<template>
  <div>
    <!--
        @slot Field placeholder. By default use field component according to `type` props with all other attributes merged.
      -->
    <slot v-bind="{ item: record, value }">
      <component
        :is="`va-${type}-field`"
        :source="source"
        :resource="resource"
        :item="record"
        v-bind="attrs"
        @show-details="showDetails"
      ></component>
    </slot>
  </div>
</template>


<script>
//import Field from "../../../mixins/field";
import get from "lodash/get";

import Vue from "vue";
//import vuetify from '../../../../vuetify'

//Vue.use(vuetify)

export default Vue.extend({
  //mixins: [Field],
  name: "GridField",
  //props: {
  //  /**
  //   * Override default label behavior.
  //   * Default is to get the localized VueI18n label from both resource and property source.
  //   */
  //  rowIndex: Number,
  //  /**
  //   * Override default source key as translated label.
  //   */
  //  prop: String,
  //  model: Object,
  //  column: Object,
  //  data: Array,
  //},
  //created: function () {},
  computed: {
    record() {
      return this.params.data;
    },
    value() {
      return this.params.value; //get(this.model, this.prop)
    },
    source() {
      return get(this.params.colDef, "field", "");
    },
    resource() {
      return get(this.params.colDef, "resource", "");
    },
    type() {
      return get(this.params.colDef, "db_type", "text");
    },
    attrs() {
      return get(this.params.colDef, "attributes", "{}");
    },
  },
  methods: {
    showDetails(e) {
      this.$emit("show-details", e);
    },
  },
});
</script>

<style>
/*
.va-input .v-input__slot {
  display: block;
}
.va-input .v-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
*/
</style>
