<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <users-show v-if="show" :item="item"></users-show>
      <users-form v-else :id="id" :item="item" @saved="onSaved"></users-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon" :title="title">
      <va-list
        ref="list"
        disable-create-redirect
        @action="onAction"
      >
        <!--
      <b v-for="item in items">{{item.name}}</b>
          -->
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        <!--
          <template v-slot:[`item.actions`]="{ resource, item }">
            <impersonate-button
              :resource="resource"
              :item="item"
              icon
            ></impersonate-button>
          </template>
          -->
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
//import ImpersonateButton from "@/components/buttons/ImpersonateButton";

//import i18n from "../../i18n";

export default {
  components: {
    //ImpersonateButton,
  },
  props: ["resource", "title"],
  data() {
    return {
      fields: [
        { source: "username", sortable: true},
        { source: "email", type: "email"},
        { source: "membership.role", type: "member", attributes:
            {tooltip: true,
             "source-icon" : "membership.icon",
             "source-name" : "membership.name",
            }
        },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  created : function() {
      this.$log.warn("Created 'resources/users/List.vue'")
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.$log.warn("Called 'onAction' from 'resources/users/List.vue'")
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.$log.warn("Called 'onSaved' from 'resources/users/List.vue'")
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
