<template>
  <div>
    <va-field
      v-for="field in fields_filtered"
      :key="field.source"
      :source="field.source"
      :label="field.label"
      :type="field.type"
      v-bind="field.attributes"
      :item="item"
    ></va-field>
  </div>
</template>

<script>

export default {
  name: "FoodValues",
  props: {
    item: Object,
    fields: Array,
    group: String,
  },
  computed: {
      user: (state) => state.auth.user,
      fields_filtered() {
          //console.log("ITEM for "+this.group)
          //console.log(this.item)
          var keys = Object.keys(this.item)
          var key = null
          var new_fields = []
          for (const field of Object.values(this.fields)) {
              key = field.source.split(".")[0]
              //console.log(key)
              if (keys.includes(key)) {
                  new_fields.push(field)
              }
          }
          return new_fields
      }
  },
  methods: {
  },
};
</script>
