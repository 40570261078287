<template>
    <div class="d-flex flex-row flex-wrap align-baseline">
       <v-menu bottom left >
          <template v-slot:activator="{ on, attrs }">
            <v-sheet
              class="d-inline-flex mr-2 justify-center align-center operator-menu-btn"
              color="white"
              elevation="2"
              height="30"
              width="30"
              v-bind="attrs"
              v-on="on"
              rounded
            >
            {{operator_symbol}}
            </v-sheet>
            </template>

            <v-list>
              <v-list-item
                v-for="(op, i) in operators"
                :key="i"
                @click="operator = op.operator;operator_symbol = op.symbol || op.operator;"
              >
              <v-list-item-title>{{ op.label }} ({{ op.symbol || op.operator }})</v-list-item-title>
              </v-list-item>
            </v-list>
      </v-menu>


      <v-textarea
        v-if="multiline"
        v-bind="commonProps"
        v-model="value_input"
        auto-grow
        :filled="filled"
      ></v-textarea>
      <v-text-field
        v-else
        v-bind="commonProps"
        v-model="value_input"
        :filled="filled"
        :type="type"
      ></v-text-field>
      <v-btn @click="form_change" small color="primary" class="ml-4">
      apply
      </v-btn>
    </div>
</template>

<script>
import Input from "../../../mixins/input";
import Editable from "../../../mixins/editable";

/**
 * Text editing for text value type via a basic text input.
 * Support textarea mode for long text via `multiline` prop.
 * Can be use for any date, datetime or time editing, use type set on `date`, `datetime-local` or `time`.
 * Render will depend of browser support.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Text to be edited.
     * @model
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Type of text input. All HTML type allowed.
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Transform text input into textarea.
     */
    multiline: Boolean,
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
        value_input : "",
        operator : "=",
        operator_symbol : "=",
        operators: [
            {operator: "=", label: "Equal"},
            {operator: "contains", symbol:"in", label: "Contains"},
            {operator: "startswith", symbol:"s", label: "Starts with"},
            //{operator: "in", label: "In list"},
            ]
    };
  },
  computed: {
      filter_value() {
          //var value = this.value_from;
          return {"op" : this.operator, "value" :this.value_input}
      },
  },
  methods: {
    form_change: function () {
        console.log("FILTER UPDATE FROM")
        //var item = this.source
        //console.log(item)
        //console.log(value)
        console.log(this.filter_value)
        this.update(this.filter_value)
      }
    },
};
</script>
