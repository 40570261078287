<template>
  <div class="d-flex align-baseline">
    <v-textarea
      v-if="multiline"
      v-bind="commonProps"
      auto-grow
      :filled="filled"
      @change="change"
      @input="update"
      :hint="getPlaceholder"
      persistent-hint
    ></v-textarea>
    <v-text-field
      v-else
      v-bind="commonProps"
      :filled="filled"
      @change="change"
      @input="update"
      :type="type"
      :hint="getPlaceholder"
      clearable
      persistent-hint
    ></v-text-field>
    <v-sheet
      class="d-inline-flex ml-2 justify-center align-center translate-btn"
      color="white"
      elevation="2"
      height="30"
      width="30"
      v-bind="attrs"
      v-on="on"
      rounded
      @click="translate"
      v-if="showFallbackTranslation"
    >
      <v-icon>mdi-translate</v-icon>
    </v-sheet>
  </div>
</template>

<script>
import Input from "../../../mixins/input";
import Editable from "../../../mixins/editable";

/**
 * Text editing for text value type via a basic text input.
 * Support textarea mode for long text via `multiline` prop.
 * Can be use for any date, datetime or time editing, use type set on `date`, `datetime-local` or `time`.
 * Render will depend of browser support.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Text to be edited.
     * @model
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Type of text input. All HTML type allowed.
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Transform text input into textarea.
     */
    multiline: Boolean,
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showFallbackTranslation() {
      return (
        !this.input && this.fallbackLang !== "" && this.getFallbackTranslation
      );
    },
    getFallbackTranslation() {
      var key = this.uniqueSourceId + "_" + this.fallbackLang;
      var item = this.formState.item;
      if (item && key in item) {
        return item[key];
      }
      return "";
    },
    getPlaceholder() {
      if (this.showFallbackTranslation) {
        return "in german: " + this.getFallbackTranslation;
      }
      return "";
    },
  },
  methods: {
    async translate() {
      console.log("Translate");
      console.log(this);
      var params = { q: this.getFallbackTranslation };
      let { data } = await this.$admin.http.get("translate", { params });
      console.log(data);
      this.update(data.text);
    },
  },
};
</script>
<style scope>
.translate-btn {
  color: #6b6b6b !important;
  cursor: pointer;
}
.translate-btn:hover {
  background-color: #fff3d0 !important;
}
</style>
