/* eslint-disable no-unused-vars */

const default_action = "grid"; // "list" or "grid"

export default (i18n, admin) => {

    var admin_children = [
        //{ divider: true },
        admin.getResourceLink({ name: "foodproducts", action: default_action }),
        admin.getResourceLink({ name: "categories", action: default_action }),
        //{ divider: true },
        admin.getResourceLink({ name: "foodproperties", action: default_action }),
        admin.getResourceLink({ name: "value_selections", action: default_action }),
    ]
    if (admin.can(["admin"])) { // add in front
        admin_children.unshift(admin.getResourceLink({ name: "users", action: default_action }))
        admin_children.unshift(admin.getResourceLink({ name: "memberships", action: default_action }))
    }
    if (admin.can(["admin"])) {
        admin_children.push(admin.getResourceLink({ name: "foodproperty_groups", action: default_action }))
    }
    //admin_children.push(admin.getResourceLink({name: "value_selections", action: "list"}))
    if (admin.can(["admin"])) {
        //admin_children.push({ divider: true })
        admin_children.push(admin.getResourceLink({ name: "units", action: default_action }))
        admin_children.push(admin.getResourceLink({ name: "unit_types", action: default_action }))
    }
    if (admin.can(["admin"])) {
        //admin_children.push({ divider: true })
        admin_children.push(admin.getResourceLink({ name: "external-dbs", action: default_action }))
    }

    return [
        //{
        //    icon: "mdi-view-dashboard",
        //    text: i18n.t("menu.dashboard"),
        //    link: "/",
        //},
        admin.can(["guest"]) && admin.getResourceLink({ name: "foodvalues-table", action: default_action }),
        admin.can(["editor"]) && { divider: true },
        admin.can(["editor"]) &&
        {
            text: "Admin",
            icon: "mdi-cog",
            expanded: false,
            children: admin_children
        },
        admin.can(["root"]) && { divider: true },
        admin.can(["root"]) && admin.getResourceLink({ name: "foodvalues", action: "list" }),
    ]
};
