//import Resource from "./resource";

/**
 * Toolbar common properties
 */
export default {
    //props: {
    data() {
        return {
            toolbarRef: null,
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.toolbarRef = this.$refs.globalToolbar;
        })
    },
    computed: {
        toolbarTitle() {
            return this.$store.state.toolbar.title;
        },
        toolbarIcon() {
            return this.$store.state.toolbar.icon;
        },
        toolbarScrollTarget() {
            return this.$store.state.toolbar.scrollTarget;
        },
        toolbarScrollTargetUpdate() {
            // use this to watch for upates, even if the name did not change
            return this.$store.state.toolbar.scrollTargetUpdate;
        },
        toolbarHeight() {
            return this.$store.state.toolbar.height;
        },
        toolbarColor() {
            return this.$store.state.toolbar.color;
        },
        toolbarProminent() {
            return this.$store.state.toolbar.prominent;
        },
        toolbarShrink() {
            return this.$store.state.toolbar.shrink;
        },
        toolbarFadeImage() {
            return this.$store.state.toolbar.fadeImage;
        },
        toolbarOffsetTop() {
            return this.$store.state.toolbar.offsetTop;
        },
        toolbarOffsetBottom() {
            return this.$store.state.toolbar.offsetBottom;
        },
        getBackgroundImageSrc() {
            var img = this.$store.state.toolbar.image;
            if (img) {
                return require(`@/assets/images/toolbars/${img}`);
            }
            return null;
        },
    },
    methods: {
        toolbarSetHeight: function (height) {
            return this.$store.commit("toolbar/setHeight", height);
        },
        toolbarUpdate: function (options) {
            return this.$store.dispatch("toolbar/update", options);
        },
        toolbarSet: function (options) {
            return this.$store.dispatch("toolbar/set", options);
        },
        toolbarSetHeader: function (header, reset) {
            if (!("reset" in header)) {
                header.reset = reset
            }
            return this.$store.dispatch("toolbar/setHeader", header);
        },
        toolbarGet: function (option) {
            return this.$store.state.toolbar[option];
        }
    },
    watch: {
        "$vuetify.breakpoint.width": {
            handler() {
                var offset = 0
                if (this.$vuetify.breakpoint.lgAndUp) {
                    offset = 105;
                } else if (this.$vuetify.breakpoint.mdAndUp) {
                    offset = 88;
                } else if (this.$vuetify.breakpoint.xsOnly) {
                    offset = 0;
                }
                this.$store.dispatch("toolbar/update", { "offsetBottom": offset })
            },
            immediate: true
        }
    }
};
