<template>
  <v-switch
    :false-value="0"
    :true-value="1"
    :input-value="input"
    v-bind="commonProps"
    @change="changeState"
  ></v-switch>
</template>

<script>
import Input from "../../../mixins/input";
import Editable from "../../../mixins/editable";

/**
 * Edit value as boolean. Rendered as a switch.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Boolean to be edited.
     * @model
     */
    value: [Boolean, Number],
  },
  methods: {
    changeState(value) {
      this.change(value);
      this.update(value);
    },
  },
};
</script>
