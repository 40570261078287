var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height only-container"},[_c('va-edit-layout',{staticStyle:{"padding-top":"150px"},attrs:{"title":_vm.title}},[_c('va-form',{attrs:{"id":_vm.id,"item":_vm.item}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-card',{attrs:{"elevation":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 0,"shaped":"","color":_vm.$vuetify.breakpoint.mdAndUp ? '#eeff3' : undefined}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.inputs_computed),function(input){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!input.hide),expression:"!input.hide"}],key:input.source,attrs:{"cols":input.attributes.multiline || input.type.includes('array')
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 6
                      : 12,"sm":input.attributes.multiline || input.type.includes('array')
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 4
                      : 6,"xl":input.attributes.multiline
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 2
                      : input.type.includes('array')
                      ? 6
                      : 3}},[_c('div',[_c(("va-" + (input.input) + "-input"),_vm._b({tag:"component",attrs:{"filled":false,"label":input.label,"name":input.db_field,"source":input.source}},'component',input.attributes,false))],1)])}),1),_c('va-save-button'),(_vm.item && (_vm.item.created || _vm.item.modified))?_c('v-divider',{staticClass:"mt-2",attrs:{"inset":""}}):_vm._e(),_c('div',{staticClass:"text--disabled text-right mt-2 mr-2",staticStyle:{"font-size":"smaller"}},[(_vm.item && _vm.item.created)?_c('va-datetime-by-field',{attrs:{"reference":"users","item":_vm.item,"source":"created_by","item-text":"username","fetch":true,"label":_vm.$t('va.common.created_at')},on:{"show-details":_vm.showDetails}}):_vm._e(),_c('br'),(
                    _vm.item && _vm.item.modified && _vm.item.created != _vm.item.modified
                  )?_c('va-datetime-by-field',{attrs:{"reference":"users","item":_vm.item,"source":"modified_by","item-text":"username","fetch":true,"label":_vm.$t('va.common.modified_at')},on:{"show-details":_vm.showDetails}}):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }