<template>
  <div class="d-flex align-baseline">
    <v-icon
      v-if="falseIcon"
      small
      style="position: relative; left: 33px; bottom: 2px"
      color="grey"
      >mdi-pencil-off</v-icon
    >
    <v-icon
      small
      v-if="trueIcon"
      :color="color"
      style="position: relative; left: 39px; bottom: 2px"
      >mdi-pencil</v-icon
    >
    <v-switch
      class="toggle-input-style"
      :color="color"
      v-model="internalValue"
      inset
    >
    </v-switch>
  </div>
</template>

<script>
//import Input from "../../../mixins/input";
//import Editable from "../../../mixins/editable";

/**
 * Edit value as boolean. Rendered as a switch.
 */
export default {
  //mixins: [Input, Editable],
  props: {
    "true-icon": String,
    "false-icon": String,
    color: { type: String, default: "success" },
    /**
     * Boolean to be edited.
     * @model
     */
    value: Boolean,
  },
  data() {
    return {
      internalValue: null,
    };
  },
  created: function () {
    this.internalValue = this.value;
  },
  watch: {
    internalValue: function (v) {
      this.$emit("input", v);
    },
  },
  //methods: {
  //  changeState(value) {
  //    this.change(value);
  //    this.update(value);
  //  },
  //},
};
</script>

<style >
.toggle-input-style.v-input--switch--inset .v-input--switch__track {
  border: 3px solid #ffffff80 !important;
  background-color: #ffffff63 !important;
}
</style>