<template>
  <div>
    <span
      v-if="showFallbackTranslation"
      class="text--disabled text-field-style"
      :style="getStyle"
      >{{ getFallbackTranslation }}
      <sup
        ><abbr style="font-size: smaller" title="German translation"
          >({{ this.fallbackLang }})</abbr
        ></sup
      >
    </span>
    <span class="text-field-style" :style="getStyle" v-else>{{ getText }}</span>
  </div>
</template>

<script>
import Field from "../../../mixins/field";
//import truncate from "lodash/truncate";

/**
 * Show value as simple text, render a simple span. HTML tags will be stripped.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Truncate text
     */
    truncate: { Boolean, default: true },
    small: { type: Boolean, default: false },
    bold: { type: Boolean, default: false },
  },
  computed: {
    getText() {
      //return this.truncate
      //  ? truncate(this.value, {
      //      length: this.truncate,
      //    })
      //  : this.value;
      return this.value; // ignore tuncate
    },
    getFallbackTranslation() {
      var key = this.source + "_" + this.fallbackLang;
      if (this.record && key in this.record) {
        return this.record[key];
      }
      return "";
    },
    showFallbackTranslation() {
      return (
        !this.getText && this.fallbackLang !== "" && this.getFallbackTranslation
      );
    },
    getStyle() {
      var style = {};
      if (this.small) {
        style["font-size"] = "smaller";
      }
      if (this.bold) {
        style["font-weight"] = "400";
      }
      if (!this.truncate) {
        style["white-space"] = "initial";
      }
      return style;
    },
  },
};
</script>
<style>
.text-field-style {
  white-space: nowrap;
}
</style>
