var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"permanent":_vm.isPinned,"clipped":_vm.clippedVariant,"app":"","dark":_vm.dark,"color":_vm.color,"mini-variant":_vm.isMini,"value":_vm.value},on:{"input":function (v) {
      _vm.$emit('input', v);
      this$1.value = v;
    }},scopedSlots:_vm._u([{key:"img",fn:function(props){return [_vm._t("img",null,null,props)]}}],null,true)},[_c('v-list-item',{staticClass:"px-2",staticStyle:{"background-color":"#00000070"},attrs:{"dense":""}},[(!_vm.isPinned)?_c('v-spacer'):_vm._e(),(!_vm.isDesktop && ((!_vm.isPhone && !_vm.isMini) || !_vm.isPinned || _vm.isPhone))?_c('v-btn',{attrs:{"ripple":false,"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.togglePinned($event)}}},[(_vm.pinned)?_c('v-icon',[_vm._v("mdi-pin-off")]):_c('v-icon',[_vm._v("mdi-pin")])],1):_vm._e(),(_vm.isPinned && !_vm.isPhone && !_vm.isMini)?_c('v-spacer'):_vm._e(),(_vm.isPinned && !_vm.isPhone)?_c('v-btn',{attrs:{"icon":"","ripple":false},on:{"click":function($event){$event.stopPropagation();return _vm.toggleMini($event)}}},[(_vm.isMini)?_c('v-icon',[_vm._v("mdi-chevron-right")]):_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e()],1),_c('v-divider'),_c('overlay-scrollbars',{staticStyle:{"height":"calc(100% - 41px)"},attrs:{"options":{
      scrollbars: { autoHide: 'leave' },
      overflowBehavior: { x: 'hidden' },
    }}},[(_vm.getUsername)?_c('v-list-item',{staticClass:"px-2",attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.get_gravatar_image_url(_vm.getEmail)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[(_vm.getMembership.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.getMembership.icon)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.getUsername)+" ")],1),_c('v-list-item-subtitle',[(_vm.getEmail)?_c('span',[_vm._v(" "+_vm._s(_vm.getEmail)+" ")]):_vm._e()])],1)],1):_vm._e(),_c('v-divider'),_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.menu.filter(function (l) { return l; })),function(item,index){return [(item.heading && !_vm.miniVariant)?_c('v-subheader',{key:index},[_vm._v(" "+_vm._s(item.heading)+" ")]):(item.divider)?_c('v-divider',{key:index}):(item.children && item.children.length)?_c('v-list-group',{key:index,attrs:{"value":item.expanded,"prepend-icon":item.icon,"append-icon":"mdi-chevron-down"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)]},proxy:true}],null,true)},_vm._l((item.children),function(child,i){return _c('v-list-item',{key:i,attrs:{"link":"","to":child.link}},[(child.icon)?_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(child.icon))])],1):_vm._e(),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(child.text)+" ")])],1)],1)}),1):(item.text)?_c('v-list-item',{key:index,attrs:{"link":"","to":item.link}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }