import { render, staticRenderFns } from "./Aside.vue?vue&type=template&id=7a443064&scoped=true&"
import script from "./Aside.vue?vue&type=script&lang=js&"
export * from "./Aside.vue?vue&type=script&lang=js&"
import style0 from "./Aside.vue?vue&type=style&index=0&id=7a443064&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a443064",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VIcon,VNavigationDrawer})
