<template>
  <div>
    <v-app-bar color="white" dense flat>
      <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->

      <!-- <v-toolbar-title>{{ title }}</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <!-- @slot Action buttons placeholder. -->
      <slot name="actions"></slot>
    </v-app-bar>
    <!-- set opacity for background to 0 -->
    <v-sheet
      style="background-color: rgba(0, 0, 0, 0)"
      height="100%"
      width="100%"
    >
      <div style="width: 100%; height: 50px"></div>

      <slot></slot>
    </v-sheet>

    <!--
    <div class="d-flex align-center mb-2">
      <h1 class="display-1">
       {{ title }}
      </h1>
      <v-spacer></v-spacer>
  -->
    <!-- @slot Action buttons placeholder. -->
    <!--
      <slot name="actions"></slot>
    </div>
  -->
    <!-- @slot Page content placeholder. -->
    <!--
    <slot></slot>
  --></div>
</template>

<script>
import Page from "../../mixins/page";

/**
 * Main CRUD action page layout used for Show, Create end Edit views components.
 */
export default {
  mixins: [Page],
};
</script>
<style>
body {
  overflow: hidden;
}
</style>
