const default_permission = [
  { name: "guest", actions: ["list", "grid", "show"] },
  { name: "editor", actions: ["list", "grid", "show", "edit", "create"] },
  { name: "admin", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const guest_editor_read_permission = [
  { name: "guest", actions: ["list", "grid", "show"] },
  { name: "editor", actions: ["list", "grid", "show"] },
  { name: "admin", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const editor_create_permission = [
  { name: "editor", actions: ["list", "grid", "show", "edit"] },
  { name: "admin", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const editor_read_permission = [
  { name: "editor", actions: ["list", "grid", "show"] },
  { name: "admin", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const admin_permission = [
  { name: "admin", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const admin_edit_permission = [
  { name: "admin", actions: ["list", "grid", "show", "edit"] },
  { name: "root", actions: ["list", "grid", "show", "edit", "create", "delete"] },
];

const translatable = true;

export default [
  // USERS
  {
    name: "users",
    icon: "mdi-account",
    background: "people.jpg",
    translatable: translatable,
    permissions: admin_permission,
  },
  {
    name: "memberships",
    api: "users/memberships",
    icon: "mdi-account-group",
    background: "people.jpg",
    translatable: translatable,
    permissions: admin_edit_permission,
  },
  // ADMIN
  {
    name: "external-dbs",
    api: "admin/external_dbs",
    icon: "mdi-database-cog",
    background: "database.jpg",
    translatable: translatable,
    permissions: admin_edit_permission,
  },
  // ADMIN - food
  {
    name: "foodvalues-table",
    api: "food/values",
    icon: "mdi-nutrition",
    background: "spices.jpg",
    translatable: translatable,
    permissions: default_permission,
  },
  {
    name: "foodproducts",
    api: "food/products",
    icon: "mdi-nutrition",
    background: "spices.jpg",
    translatable: translatable,
    permissions: default_permission,
  },
  {
    name: "foodproperties",
    api: "food/properties",
    icon: "mdi-clipboard-list",
    background: "spoons.jpg",
    translatable: translatable,
    permissions: editor_read_permission,
  },
  {
    name: "foodproperty_groups",
    api: "food/properties/groups",
    icon: "mdi-clipboard-text-multiple",
    translatable: translatable,
    permissions: editor_read_permission,
  },
  {
    name: "value_selections",
    api: "food/values/selections",
    icon: "mdi-format-list-bulleted-type",
    background: "spoons_top.jpg",
    translatable: translatable,
    permissions: editor_create_permission,
  },
  {
    name: "categories",
    api: "food/categories",
    icon: "mdi-tag-multiple",
    background: "categories.jpg",
    translatable: translatable,
    permissions: guest_editor_read_permission,
  },
  {
    name: "units",
    api: "food/units",
    icon: "mdi-ruler",
    background: "scale.jpg",
    translatable: translatable,
    permissions: editor_read_permission,
  },
  {
    name: "unit_types",
    api: "food/units/types",
    icon: "mdi-shape",
    background: "scale.jpg",
    translatable: translatable,
    permissions: admin_permission,
  },
  // APP
  {
    name: "foodvalues",
    api: "food/values",
    icon: "mdi-food",
    routes: ["list", "grid", "show"],
    background: "spices.jpg",
    translatable: translatable,
    permissions: default_permission,
  },


];
