import get from "lodash/get";
export default {
    namespaced: true,
    state: {
        height: 0,
        shrinkedHeight: 58, // only used to calc OffsetTop
        offsetTop: 0,
        offsetBottom: 0,
        title: undefined,
        image: "default.jpg",
        prominent: true,
        shrink: true,
        fadeImage: false,
        icon: undefined,
        color: "#131b20",
        scrollTarget: null,
        scrollTargetUpdate: 1,
        hide: false,
        default_values: {
            title: undefined,
            image: "default.jpg",
            prominent: true,
            shrink: true,
            fadeImage: false,
            icon: undefined,
            color: "#131b20",
            scrollTarget: null,
            hide: false,
        }
    },
    mutations: {
        set(state, params) {
            for (var key of Object.keys(state)) {
                if (key in params) {
                    state[key] = params[key]
                } else if (key in state.default_values) {
                    state[key] = state.default_values[key]
                }
                if (key == "scrollTarget" && state[key] !== undefined) {
                    state.scrollTargetUpdate = state.scrollTargetUpdate + 1
                }
            }
        },
        update(state, params) {
            for (var key of Object.keys(params)) {
                state[key] = params[key]
                if (key == "scrollTarget" && state[key] !== undefined) {
                    state.scrollTargetUpdate = state.scrollTargetUpdate + 1
                }
            }
        },
        setHeight(state, height) {
            state.height = height
            state.offsetTop = (height - state.shrinkedHeight) * 0.3;
        },
        setScrollTarget(state, target) {
            state.scrollTarget = target
            state.scrollTargetUpdate = state.scrollTargetUpdate + 1
        },
    },
    actions: {
        setHeader({ commit, dispatch }, params) {
            if (get(params, "reset", false)) {
                dispatch('reset')
            }
            var header = {}
            header.title = get(params, "title", undefined)
            header.icon = get(params, "icon", undefined)
            header.image = get(params, "image", "default.jpg")
            if ("color" in params) {
                header.color = get(params, "color", undefined)
            }
            commit("update", header)
        },
        update({ commit }, params) {
            commit("update", params)
        },
        set({ commit }, params) {
            commit("set", params)
        },
        reset({ state, dispatch }) {
            dispatch("update", state.default_values)
        },
    },
};
