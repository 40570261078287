import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
  CHECK_ERROR,
  GET_NAME,
  GET_EMAIL,
  GET_ROLE,
  GET_PERMISSIONS,
  GET_MEMBERSHIP,
} from "./actions";

import FetchJson from "../utils/fetchJson";

export default (httpClient, params = {}) => {
  if (typeof httpClient === "string") {
    httpClient = new FetchJson(httpClient);
  }

  params = {
    routes: {
      login: "auth/tokens",
      logout: "auth/tokens",
      refresh: "auth/tokens/refresh",
      user: "users/me",
    },
    storageKey: "bearer_token",
    getToken: (r) => r.token,
    getCredentials: ({ username, password }) => {
      return {
        username: username,
        password: password,
      };
    },
    getName: (u) => u.username,
    getEmail: (u) => u.email,
    getRole: (u) => u.role,
    getMembership: (u) => u.membership,
    getPermissions: (u) => {
        return u.roles
        },
    ...params,
  };

  let {
    routes,
    storageKey,
    getCredentials,
    getName,
    getEmail,
    getRole,
    getMembership,
    getPermissions,
    getToken,
  } = params;

  return {
    [LOGIN]: async ({ username, password }) => {
      let response = await httpClient.post(
        routes.login, {}, // empty body, auth header added 
        {auth: getCredentials({ username, password })}
        );
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      localStorage.setItem(storageKey, getToken(response.data));
      return Promise.resolve();
    },
    [LOGOUT]: async () => {
      if (routes.logout); {
          // changed from post to delete
        await httpClient.delete(routes.logout);
      }

      localStorage.removeItem(storageKey);
      return Promise.resolve();
    },
    [CHECK_AUTH]: async () => {
      /**
       * Add token to header
       */
      httpClient.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.getItem(storageKey)
      /**
       * Refresh token
       */
      if (routes.refresh) {
        let response = await httpClient.post(routes.refresh);
        localStorage.setItem(storageKey, getToken(response.data));
        // update header
        httpClient.defaults.headers.common['Authorization'] = 'Bearer '+ getToken(response.data)
      }

      /**
       * Get user infos
       */
      if (routes.user) {
        let response = await httpClient.get(routes.user);
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response;
      }

      return localStorage.getItem(storageKey)
        ? Promise.resolve({
            data: true,
          })
        : Promise.reject();
    },
    [CHECK_ERROR]: ({ status }) => {
      if (status === 401 || status === 403) {
        localStorage.removeItem(storageKey);
        return Promise.reject();
      }
      return Promise.resolve();
    },
    [GET_NAME]: (user) => getName(user),
    [GET_EMAIL]: (user) => getEmail(user),
    [GET_ROLE]: (user) => getRole(user),
    [GET_MEMBERSHIP]: (user) => getMembership(user),
    [GET_PERMISSIONS]: (user) => getPermissions(user),
  };
};
