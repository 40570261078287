import Vue from "vue";
import VuetifyAdmin from "./vuetify-admin";

import "./vuetify-admin/loader";


import {
  //jsonServerDataProvider,
  jsonAPIDataProvider,
  tokenAuthProvider,
} from "./vuetify-admin/providers";
import {
  en, de,
} from "./vuetify-admin/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:5000/api/v1";

const http = axios.create({
  baseURL,
  headers: { "X-Requested-With": "XMLHttpRequest" },
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "Foodtool Beta",
  routes,
  locales: {
    de, en,
  },
  translations: [
    "de","en",
  ],
  dataProvider: jsonAPIDataProvider(http),
  authProvider: tokenAuthProvider(
    http
  ),
  resources,
  http,
  options: {
    dateFormat: "short",
    numberFormat: "float",
    list: {itemsPerPage:50},
  },
    // any other custom actions on given resource and action...
});
