/**
 * We will try to guess the best suitable fields type for each item property.
 *
 */

//import { VGridVueTemplate } from "@revolist/vue-datagrid";
//import GridField from "../components/ui/wrappers/GridField";


let guessFields = async (store, i18n, resource) => {
  //let { data } = await store.dispatch(`${resource}/getList`, {
  //  pagination: { page: 1, perPage: 1 },
  //});

  var disableCache = [1, "1", "true", true].includes(process.env.VUE_APP_DISABLE_CACHE) || false

  var dispatch_call = store.dispatch
  if (!disableCache && store.cache) {
    dispatch_call = store.cache.dispatch
  }
  let data = await dispatch_call(`${resource}/getSchema`, {
    lang: i18n.locale, theme: "light"
  });

  // schema available.
  //if (!data.length) {
  //  return [];
  //}

  let item = data.fields;

  var fields = Object.keys(item)
    .map((prop) => {
      var ele = item[prop]
      var attributes = ele.options;
      var type = ele.type;
      var input = ele.input;
      var translatable = ele.translations ? true : false
      attributes.fallbackLang = "de";
      if (type == "datetime") {
        attributes.format = "datetime-short"
      }
      // use text for some special fields
      if (["icon", "member", "url", "email"].includes(type)) {
        input = "text";
      }
      if (type.includes("reference")) {
        //attributes.reference = ele.options.reference;
        attributes.action = "show";
        attributes.aside = true;
        //attributes.multiple = true;
        //attributes.fetch = ele.options.fetch;
        attributes.itemText = ele.options.field;
        attributes.itemInputText = ele.options.input_field;
        attributes.itemsPerPage = 0;
        input = "select";

      }

      return {
        source: ele.field, type: type, label: ele.label,
        attributes: attributes, default: ele.default ? ele.default : null, sortable: ele.sortable,
        translatable: translatable, db_field: ele.db_field, input: input,
        width: ele.width, editable: ele.editable,
        // revo-grid:
        prop: ele.db_field, name: ele.label, columnType: type,
        resource: resource,
        // ag-grid:
        pinned: ele.pinned,
        field: ele.field,
        hide: ele.hide

        //field: ele.field, headerName: ele.label //, columnType: type,
        //cellTemplate: cellTemplate
      };
    });
  //dispatch_call(`${resource}/setFields`, fields);
  return fields;

  //if (!data.length) {
  //  return [];
  //}

  //let item = data[0];

  //const supportedFields = [
  //  { type: "boolean", validator: (v) => typeof v === "boolean" },
  //  { type: "number", validator: (v) => typeof v === "number" },
  //  { type: "email", validator: (v) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) },
  //  {
  //    type: "url",
  //    validator: (v) => {
  //      try {
  //        new URL(v);
  //      } catch (e) {
  //        return false;
  //      }

  //      return true;
  //    },
  //  },
  //  {
  //    type: "date",
  //    validator: (v) => {
  //      return isNaN(v) && !isNaN(Date.parse(v));
  //    },
  //  },
  //  { type: "text", validator: (v) => typeof v === "string" },
  //];

  //return Object.keys(item)
  //  .map((prop) => {
  //    if (i18n.te(`resources.${resource}.enums.${prop}`)) {
  //      return { source: prop, type: "select" };
  //    }
  //    for (let t of supportedFields) {
  //      if (t.validator(item[prop])) {
  //        return { source: prop, type: t.type };
  //      }
  //    }
  //    return false;
  //  })
  //  .filter((t) => t && t.source !== "id");
};

let guessInputs = async (store, i18n, resource) => {
  let fields = await guessFields(store, i18n, resource);

  return fields.map((f) => {
    if (["email", "url", "reference"].includes(f.type)) {
      f.type = "text";
    }
    if (["datetime"].includes(f.type)) {
      f.type = "date";
    }

    return f;
  });
};

let guessCreateInputs = async (store, i18n, resource) => {
  let fields = await guessInputs(store, i18n, resource);

  return fields.filter((t) => t &&
    !(t.source.includes("created") || t.source.includes("modified")));
};

export { guessFields, guessInputs, guessCreateInputs };
