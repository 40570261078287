<template>
    <v-tooltip top open-delay=500 small
        :disabled="! tooltip">
      <template v-slot:activator="{ on, attrs }">
            <i aria-hidden="true" class="v-icon notranslate v-icon--left mdi theme--light" :class="value" style="font-size: 18px;"
                v-on="on"
                v-bind="attrs"
                 v-if="value"
             ></i>
            <!-- TODO
            <v-icon  
                   v-bind="attrs"
                  v-on="on" 
            v-if="value"> {{value}} </v-icon>
                  -->
      </template>
      {{value}}
    </v-tooltip>
</template>

<script>
import Field from "../../../mixins/field";
//import get from "lodash/get";

/**
 * Show value inside a material chip.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Constraint image to full width instead of cover.
     * Ideal for logos.
     */
    tooltip: {type: Boolean, default: true},
    "content-class": {type: String, default: ""},
  },
};
</script>

<style>
.member-field-style {
    background-color: #ffc84b !important;
    padding: 9px 5px 8px 9px;
        border: 1px solid #e0ac34;
}
</style>
