<template>
  <v-autocomplete
    v-bind="commonProps"
    :multiple="multiple"
    :chips="chips"
    :small-chips="smallChips"
    :loading="loading"
    item-text="name"
    item-value="id"
    :items="items"
    v-model="values"
    :return-object="returnObject"
    @input="update"
  >
    <template v-slot:selection="data">
      <va-tag :color="data.item.color" :label="data.item.name" />
    </template>
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>
          <va-tag :color="data.item.color" :label="data.item.name" />
        </v-list-item-title>
        <v-list-item-subtitle v-html="data.item.description">
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <!-- @change="change"
    @input="update" -->
    <!-- :item-value="getItemValue" -->
    <!-- :items="items || choices" -->
    <!-- :search-input.sync="search" -->

    <!-- <template v-slot:selection="data" v-if="$scopedSlots.selection"> -->
    <!-- @slot Define a custom selection appearance -->
    <!-- <slot name="selection" v-bind="data"></slot> -->
    <!-- </template> -->
    <!-- <template v-slot:item="data" v-if="$scopedSlots.item"> -->
    <!-- @slot Define a custom item appearance -->
    <!-- <slot name="item" v-bind="data"></slot> -->
    <!-- </template> -->
  </v-autocomplete>
</template>

<script>
import Input from "../../../mixins/input";
//import Multiple from "../../../mixins/multiple";
//import ReferenceInput from "../../../mixins/reference-input";

/**
 * Value editing from a searchable choices. Support multiple and references.
 * Allows searching of linked resources from your API.
 */
export default {
  //mixins: [Input, Multiple, ReferenceInput],
  mixins: [Input],
  props: {
    /**
     * Minimum characters to tap before search query launch.
     */
    minChars: {
      type: Number,
      default: 3,
    },
    /**
     * Name of request query for searching into your API.
     */
    searchQuery: {
      type: String,
      default: "q",
    },
    /**
     * Changes the selection behavior to return the object
     * directly rather than the value specified with
     * item-value.
     */
    returnObject: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: null,
      multiple: false,
      chips: true,
      smallChips: true,
      loading: true,
      propertyId: null,
      values: null,
      items: [],
    };
  },
  created: function () {
    if (this.$attrs.value) {
      this.input = this.$attrs.value;
    }
    // get col id
    this.propertyId = Number(this.source.split(".")[0].split("_")[1]);
    this.$admin.http
      .get(`/food/properties/${this.propertyId}/details`)
      .then(({ data }) => {
        this.items = data.unit.selections;
        this.loading = false;
        var _type = data.unit.unit_type;
        if (["multiselect", "multiselect_or_text"].includes(_type)) {
          this.multiple = true;
        }
        if (this.multiple) {
          this.values = this.input;
          // this.values = this.input.map((obj) => {
          //   return obj.id;
          // });
        } else if (this.input.length >= 1) {
          this.values = this.input[0];
        }
        //console.log("Details for " + data.name, data);
        //console.log("Property ID: ", this.propertyId);
        //console.log("Autocomplete, items:", this.items);
        //console.log("              values:", this.values);
        //console.log("THIS:", this);
      });
    //this.items = this.input;
  },
  methods: {
    //async loadCurrentChoices(value) {
    //  if (this.reference && value) {
    //    this.items = await this.fetchCurrentChoices(
    //      this.multiple ? value : [value]
    //    );
    //  }
    //},
  },
  watch: {
    //values: {
    //  handler(newVals) {
    //    console.log("Values updated: ", newVals);
    //  },
    //},
    //input: {
    //  handler(newVal) {
    //    /**
    //     * Fetch full object as soon as we get input value
    //     */
    //    this.loadCurrentChoices(newVal);
    //  },
    //  immediate: true,
    //},
    //async search(val, old) {
    //  if (old === null) {
    //    return;
    //  }
    //  if (!val || val.length < this.minChars) {
    //    return;
    //  }
    //  this.items = [
    //    ...(this.items || []),
    //    ...((await this.fetchChoices(val)) || []),
    //  ];
    //},
  },
};
</script>
