<template>
  <va-action-button
    v-if="hasAction('list')"
    :hide-label="icon"
    :label="$t('va.actions.translate')"
    icon="mdi-translate"
    :color="color || 'blue'"
    text
    @click="onTranslate"
  ></va-action-button>
</template>


<script>
import Button from "../../../mixins/button";

/**
 * Button for all delete resource action. Comes with confirm dialog.
 * Auto hide if no delete action available unless show prop is active.
 */
export default {
  mixins: [Button],
  props: {
    /**
     * Redirect to resource list after successful deletion.
     * Default redirect active if current page is resource being deleted.
     */
    redirect: Boolean,
  },
  methods: {
    async onTranslate() {
      if (!this.item) {
        /**
         * Custom delete action if no item.
         * Used for bulk delete button which has his custom logic.
         */
        this.$emit("translate");
        return;
      }

      // TODO
      // 1. get item with fallback language
      // 2. run translation
      // 3. update value
      // 4. done

      //await this.$store.dispatch(`${this.resource}/delete`, {
      //  id: this.item.id,
      //});

      //if (this.redirect) {
      //  this.$router.push({ name: `${this.resource}_list` });
      //  return;
      //}

      //this.$store.dispatch("api/refresh", this.resource);

      /**
       * Triggered on successful deletion of resource item.
       */
      this.$emit("translated");
    },
  },
};
</script>
