<template>
  <v-chip class="tag-field-style" x-small label :color="color">
    <!-- @slot Default chip content slot, use simple text or select field by default. -->
    <slot>
      <span :style="{ color: textColor + ' !important' }">{{ label }}</span>
    </slot>
  </v-chip>
</template>

<script>
import Field from "../../../mixins/field";
import Chip from "../../../mixins/chip";

/**
 * Show each single value of multiple array type value as material chip group.
 */
export default {
  mixins: [Field, Chip],
  props: {
    color: { type: String, default: "#fffca8" },
    label: String,
  },
  computed: {
    textColor() {
      var hexcolor = this.color.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
  },
};
</script>

<style scoped>
.tag-field-style {
  /* background-color: #ffc84b !important; */
  padding: 9px 5px 8px 5px;
  margin-right: 3px;
  /* border: 1px solid #ce2626c0; */
}
</style>
