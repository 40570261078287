<template>
  <v-form ref="form" @submit.prevent="validate">

  <div style="height: 45px;" />
    <!-- username input -->
    <v-text-field
      :label="$t('auth.username')"
      prepend-icon="mdi-account"
      v-model="form.username"
      ref="username"
      name="username"
      required
      :outlined="false"
      placeholder=" "
      autocomplete="username"
      autocapitalize="off"
      :error-messages="errorMessages.email"
      tabindex=1
      @animationstart="updateAutofilled"
    ></v-text-field>
    <!-- register link -->
    <div class="text-right text-body-2 label-below">
      <router-link :to="{ name: 'register' }">
        {{ $t("auth.register") }}
      </router-link>
    </div>


    <!-- password input -->
    <v-text-field
      :label="$t('auth.password')"
      prepend-icon="mdi-lock"
      ref="password"
      name="password"
      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show_password ? 'text' : 'password'"
      v-model="form.password"
      autocomplete="off"
      autocapitalize="off"
      placeholder=" "
      tabindex=2
      @animationstart="updateAutofilled"
      @click:append="show_password = !show_password"
      required
    ></v-text-field>
    <!-- forgot password link -->
    <div class="text-right text-body-2  label-below">
      <router-link :to="{ name: 'forgot_password' }">
        {{ $t("auth.forgot_password") }}
      </router-link>
    </div>


    <div class="text-center mt-15">
      <v-btn
        :loading="loading"
        color="secondary"
        large
        type="submit"
        rounded
        tabindex=3
        >{{ $t("auth.sign_in") }}
      </v-btn >
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      show_password: false,
      autofilled : {},
      form: {
        username: null,
        password: null,
      },
      errorMessages: {},
      loading: false,
    };
  },
  created: function() {
      for (var key in this.form) {
          this.autofilled[key] = false
      }
  },
  mounted: function() {
      // used as auto-fill trick
		this.$nextTick(() => {
            document.activeElement.blur();
            this.form.username = "";
            setTimeout(() => {document.activeElement.blur()}, 200);
            setTimeout(() => {
                for (var key in this.form) {
                    if (this.autofilled[key] ) {
                        this.form[key] = " "
                    }
                }
                this.$nextTick(() => {
                    document.activeElement.blur();
                });
                }, 600);
            });
        },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.login(this.form);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }
          this.errorMessages = { email: [e.message] };
        } finally {
          this.loading = false;
        }
      }
    },
    updateAutofilled(e) {
        var name = e.srcElement.name
        if(e.animationName == "onAutoFillStart") {
            this.autofilled[name] = true;
        } else if(e.animationName == "onAutoFillCancel") {
            this.autofilled[name] = false;
        }
    }
  },
  watch: {
  }
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
.label-below {
    position: relative;
    top: -18px;
}

</style>


