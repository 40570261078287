<template>
  <form>
    <v-row>
      <v-col sm="auto" v-for="(item, index) in filters" :key="index">
        <div class="d-flex align-center v-text-field--outlined theme--light">
          <fieldset class="d-flex align-center filter-form-style v-text-field--outlined theme--light">
              <legend>&nbsp;{{item.label}}&nbsp;</legend>
              <v-btn
                text
                small
                icon
                class="mr-2 filter-close-btn"
                v-if="!item.alwaysOn"
                @click="remove(item)"
              >
              <!--<v-icon>mdi-close-circle-outline</v-icon>-->
                <v-icon>mdi-filter-off-outline</v-icon>
              </v-btn>
              <slot
                :name="item.source"
                :source="item.source"
                :label="item.label"
                v-bind="item.attributes"
                :value="value[item.source]"
              >
                <input-filter
                  style="margin-top: -10px;"
                  :type="item.filter || item.type"
                  :source="item.source"
                  label=" "
                  v-bind="item.attributes"
                  :value="value[item.source]"
                  @input="(val) => update(item, val)"
                >
                </input-filter>
              </slot>
          </fieldset>
        </div>
      </v-col>
    </v-row>
  </form>
</template>

<script>
import InputFilter from "./InputFilter";

export default {
  components: {
    InputFilter,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    remove(filter) {
      let value = { ...this.value };
      delete value[filter.source];

      this.$emit("remove", filter);
      this.$emit("input", value);
    },
    update(item, value) {
        console.log("FILTER UPDATE")
        console.log(item)
        console.log(value)
        console.log(this.value)
        console.log({
            ...this.value,
            [item.source]: value,
          })

      this.$emit("input", {
        ...this.value,
        [item.source]: value,
        //[item.source+"[=]"]: value,
      });
    },
  },
};
</script>
<style>
.filter-form-style {
    padding: 8px;
    color: #1867c0;
    pointer-events: initial !important;
    position: initial !important;
    border-width: 2px !important;
}
</style>


<style scoped>
.filter-close-btn {
  position: absolute;
  top: -8px;
  right: 2px;
  background-color: white;
  color: #1867c0 !important;
  }
</style>
