<template>
  <div>
    <v-btn
      v-if="col_id !== undefined"
      style="margin-left: -10px; color: gray; background-color: #00000000"
      icon
      :to="{ name: this.resource + '_' + this.action, params: { id: col_id } }"
      small
    >
      <!-- @click="updateItem" -->
      <v-icon>{{ icon }}</v-icon>
    </v-btn>
    <!-- <v-progress-circular
      v-else
      small
      indeterminate
      color="primary"
    ></v-progress-circular> -->
    <!-- <v-btn icon small style="color: gray; background-color: white">
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn> -->
    <!--
        @slot Field placeholder. By default use field component according to `type` props with all other attributes merged.
    <slot v-bind="{ item: record, value }">
      <component
        :is="`va-${type}-field`"
        :source="source"
        :resource="resource"
        :item="record"
        v-bind="attrs"
        @show-details="showDetails"
      ></component>
    </slot>
      -->
  </div>
</template>


<script>
//import Field from "../../../mixins/field";
import get from "lodash/get";

import Vue from "vue";
//import vuetify from '../../../../vuetify'

//Vue.use(vuetify)

export default Vue.extend({
  //mixins: [Field],
  name: "AgGridActions",
  //props: {
  //  /**
  //   * Override default label behavior.
  //   * Default is to get the localized VueI18n label from both resource and property source.
  //   */
  //  rowIndex: Number,
  //  /**
  //   * Override default source key as translated label.
  //   */
  //  prop: String,
  //  model: Object,
  //  column: Object,
  //  data: Array,
  //},
  //created: function () {},
  computed: {
    action() {
      return get(this.params.colDef, "action", "show");
    },
    icon() {
      switch (this.action) {
        case "show":
          return "mdi-eye";
        case "edit":
          return "mdi-pencil";
        case "delete":
          return "mdi-delete";
        case "clone":
          return "mdi-content-duplicate";
      }
      return get(this.params.colDef, "icon", "mdi-pencil");
    },
    record() {
      return this.params.data;
    },
    col_id() {
      return this.value;
    },
    value() {
      return this.params.value; //get(this.model, this.prop)
    },
    source() {
      return get(this.params.colDef, "field", "");
    },
    resource() {
      return get(this.params.colDef, "resource", "");
    },
    type() {
      return get(this.params.colDef, "db_type", "text");
    },
    attrs() {
      return get(this.params.colDef, "attributes", "{}");
    },
  },
  watch: {
    "this.params.value": function () {
      console.log("Data parameter updated", this.params);
    },
  },
  created: function () {
    //console.log("Actions:", this.params);
  },
  methods: {
    updateItem() {
      console.log("Set item than redirect");
      /**
       * Get freshed item
       */
      //   let { data } = await this.$store.dispatch(`${this.resource}/getOne`, {
      //     id: this.col_id,
      //   });
      //this.$store.commit(`${this.resource}/setItem`, this.record);
    },
    showDetails(e) {
      this.$emit("show-details", e);
    },
    onAction(action, item) {
      console.log("Run action '" + action + "': ", item);
    },
  },
});
</script>

<style>
/*
.va-input .v-input__slot {
  display: block;
}
.va-input .v-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
*/
</style>
