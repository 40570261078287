<template>
  <span v-if="value !== null">
      <span v-if="label">{{label}} </span>
      <span>{{ dateFormatted(datetime)}}</span>
      <slot :value="value" :item="referenceItem" v-if="!this.loading">
      {{ $t("va.common.by") }} <a @click="showDetails" >{{ getItemText }} </a>
       </slot>
  </span>
</template>

<script>
import Field from "../../../mixins/field";
import Reference from "../../../mixins/reference";

/**
 * Display a reference link to another existing resource. Can auto fetch the the target resource from source ID if asked.
 */
export default {
  mixins: [Field, Reference],
  props: {
    /**
     * Name of date format to use. Must be predefined on your VueI18n plugin.
     */
    format: {
      type: String,
      default() {
        return "datetime-short";
      },
    },
    fetch: Boolean,
    aside: Boolean,
    label: String,
  },
  data: () => {
    return {
      loading: false,
      datetime: null,
      referenceItem: null,
    };
  },
  created : function() {
      this.datetime = this.item.created
  },
  computed: {
    getId() {
      return this.referenceItem
        ? this.referenceItem[this.itemValue]
        : this.value;
    },
    link() {
      let resource = this.$admin.getResource(this.reference);

      if (resource.routes.includes(this.action)) {
        return {
          name: `${this.reference}_${this.action}`,
          params: {
            id: this.getId,
          },
        };
      }

      return null;
    },
    getItemText() {
      if (!this.referenceItem) {
        return this.value;
      }
      let resource = this.$admin.getResource(this.reference);
      let text = this.itemText || resource.label;

      if (typeof text === "function") {
        return text(this.referenceItem);
      }
      return this.referenceItem[text] || this.referenceItem;
    },
  },
  watch: {
    value: {
      async handler(newVal) {
        if (newVal === Object(newVal)) {
          /**
           * Full reference object is already loaded
           */
          this.referenceItem = newVal;
          return;
        }

        if (this.fetch) {
          this.loading = true;
          let { data } = await this.$store.dispatch(
            `${this.reference}/getOne`,
            { id: newVal }
          );

          this.referenceItem = data;
          this.loading = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    dateFormatted(val) {
      if (val) {
        return this.$d(new Date(val), this.format);
      }
    },
    showDetails() {
        console.log("Clicked reference field")
        var action = "show";
        var title = this.getItemText;
        var id = this.value;
        var data = this.referenceItem;
        var reference = this.reference;
        var e = { action, title, id, item:data, reference };
      this.$emit("show-details", e);
    },
  },
};
</script>
