<template>
  <!--
  <form @submit.prevent="save">

    TODO check probs from DataTable.vue
  -->
  <!-- <div style="height: 150vh"> -->
  <!-- :rowData="items" -->
  <div style="height: 100%">
    <ag-grid
      id="ag-grid"
      style="width: 100%; height: 100%"
      class="ag-theme-material"
      :columnDefs="headers"
      :frameworkComponents="frameworkComponents"
      :rowSelection="rowSelection"
      :gridOptions="gridOptions"
      :cacheBlockSize="cacheBlockSize"
      maxConcurrentDatasourceRequests="5"
      :pagination="pagination"
      paginationAutoPageSize
      :suppressClickEdit="!editable"
      stopEditingWhenGridLosesFocus
      singleClickEdit="false"
      rowBuffer="15"
      @cellValueChanged="cellValueChanged"
    ></ag-grid>
  </div>
  <!--
  </form>
    -->
</template>

<script>
import upperFirst from "lodash/upperFirst";
import AgGridField from "../wrappers/AgGridField";
import AgGridInput from "../wrappers/AgGridInput";
import AgGridActions from "../wrappers/AgGridActions";
import Resource from "../../../mixins/resource";
/**
 * Data table component, you will need data iterator as `VaList` in order to make it usable.
 * This component allows you to template all fields columns.
 */
export default {
  mixins: [Resource],
  //inject: {
  //  listState: { default: undefined },
  //},
  props: {
    /**
     * Make each row clickable. Use predefined function as edit or show.
     * @values show, edit
     */
    rowClick: {
      type: [String, Boolean],
      default: null,
      validator: (v) => ["show", "edit"].includes(v),
    },
    /**
     * List of columns for each property of resource data.
     * Each column can be a simple string or a full object with advanced field properties.
     * Valid properties are `source`, `type`, `label`, `sortable`, `align`, `link`, `attributes`, `editable`.
     */
    fields: {
      type: Array,
      default: () => [],
    },
    cacheBlockSize: {
      type: Number,
      default: 100,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    ///**
    ///**
    // * Reduce height of each row.
    // */
    //dense: Boolean,
    ///**
    // * Enable multi sort feature, enabled by default.
    // */
    //multiSort: {
    //  type: Boolean,
    //  default: true,
    //},
    ///**
    // * Enable row expand mode.
    // * Use it for quick detail view.
    // */
    //showExpand: Boolean,
    ///**
    // * Only one row can expanded at once
    // */
    //singleExpand: {
    //  type: Boolean,
    //  default: true,
    //},
    ///**
    // * Disable select feature.
    // */
    //disableSelect: Boolean,
    ///**
    // * Disable sorting.
    // */
    //disableSort: Boolean,
    ///**
    // * Disable show action row.
    // */
    //disableShow: Boolean,
    ///**
    // * Disable show action row.
    // */
    //disableEdit: Boolean,
    ///**
    // * Disable clone action row.
    // */
    //disableClone: Boolean,
    ///**
    // * Disable delete action row.
    // */
    //disableDelete: Boolean,
    ///**
    // * Disable actions column.
    // */
    //disableActions: Boolean,
    ///**
    // * Disable create redirection. Will force clone button to show.
    // */
    //disableCreateRedirect: Boolean,
    ///**
    // * Disable show redirection. Will force show button to show.
    // */
    //disableShowRedirect: Boolean,
    ///**
    // * Disable edit redirection. Will force edit button to show.
    // */
    //disableEditRedirect: Boolean,
    ///**
    // * Association infos object in case of this list is related to a current show or edit resource page.
    // * Enable the dissociation between resources directly by an additional dissociation action.
    // */
    //association: {
    //  type: Object,
    //  default: () => {},
    //},
    ///**
    // * Allow new item row.
    // */
    //rowCreate: Boolean,
    ///**
    // * Allow editable row.
    // */
    //rowEdit: Boolean,
    ///**
    // * Additional form object data to merge with row-create form.
    // */
    //createData: {
    //  type: Object,
    //  default: () => {},
    //},
    ///**
    // * Additional form object data to merge with row-edit form.
    // */
    //updateData: {
    //  type: Object,
    //  default: () => {},
    //},
  },
  data() {
    return {
      editRowId: null,
      form: null,
      errors: {},
      saving: false,
      frameworkComponents: null,
      gridOptions: {
        rowModelType: "infinite",
        datasource: { getRows: this.fetchData },
        undoRedoCellEditing: true,
        undoRedoCellEditingLimit: 10,
        enableCellChangeFlash: true,
        getRowNodeId: (data) => {
          return data.id;
        },
      },
      gridApi: null,
      rowSelection: "single",
      items: [],
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
  },
  created() {
    // Events used to support copy & paste
    window.addEventListener("copy", this.sendToClipboard);
    window.addEventListener("cut", this.sendToClipboard);
    window.addEventListener("paste", this.fromClipboard);

    // Components used by Ag-Grid
    this.frameworkComponents = {
      GridField: AgGridField,
      GridInput: AgGridInput,
      GridActions: AgGridActions,
    };

    //this.fetchData({
    //  startRow: 0,
    //  endRow: 100,
    //  successCallback: ({ data, total }) => {
    //    this.items = data;
    //    console.log("Data: ", data);
    //    console.log("Total: ", total);
    //  },
    //});
  },
  destroyed() {
    // Remove events used to support copy & paste
    window.removeEventListener("copy", this.sendToClipboard);
    window.removeEventListener("cut", this.sendToClipboard);
    window.removeEventListener("paste", this.fromClipboard);
  },
  computed: {
    loading() {
      return false; //this.listState.loading;
    },
    //items() {
    //  if (this.form && !this.editRowId) {
    //    return [{ _new: true }, ...this.listState.items];
    //  }
    //  return this.listState.items;
    //},
    headers() {
      let fields = this.getFields.map((field) => {
        var _filter = false;
        if (field.type == "number") {
          _filter = "agNumberColumnFilter";
        } else if (field.type == "text") {
          _filter = "agTextColumnFilter";
        }
        var _cellEditor = "agTextCellEditor";
        if (field.attributes.multiline) {
          _cellEditor = "agLargeTextCellEditor";
        }
        if (
          field.input.includes("select") ||
          ["color", "tags", "category"].includes(field.input)
        ) {
          _cellEditor = "GridInput";
        }
        return {
          colId: field.db_field,
          field: field.field,
          headerName: field.label,
          headerTooltip: field.label.length > 10 ? field.label : "",
          //type: field.type,
          db_type: field.type,
          db_input: field.input,
          initialPinned: field.pinned,
          resource: field.resource,
          attributes: field.attributes,
          cellRenderer: "GridField",
          cellEditor: _cellEditor,
          filter: _filter,
          resizable: true,
          width: field.width ? field.width : 100, // todo use minWidth, maxWidth and flex
          editable: field.editable,
          sortable: field.sortable,
          hide: field.hide,
          //align: field.align || this.getDefaultAlign(field),
        };
      });
      //console.log("Header:");
      //console.log(fields);
      fields.unshift({
        field: "id",
        colId: "actionShow",
        headerName: "",
        cellRenderer: "GridActions",
        //cellEditorFramework: "ActionsEditor",
        //valueSetter: () => null,
        editable: false,
        width: 40,
        pinned: "left",
        resource: this.resource,
        action: "show",
        icon: "mdi-eye",
      });
      fields.unshift({
        field: "id",
        colId: "actionEdit",
        headerName: "",
        cellRenderer: "GridActions",
        //cellEditorFramework: "ActionsEditor",
        //valueSetter: () => null,
        hide: !this.editable,
        editable: false,
        width: 40,
        pinned: "left",
        resource: this.resource,
        action: "edit",
        icon: "mdi-pencil",
      });
      return fields;
    },
    getFields() {
      return this.fields
        .map((f) => {
          return typeof f === "string" ? { source: f } : f;
        })
        .map((f) => {
          return {
            ...f,
            type: f.type,
            label:
              f.label ||
              this.$admin.getSourceLabel(this.resource, f.labelKey || f.source),
          };
        });
    },
  },
  watch: {
    editable: {
      handler(editable) {
        if (this.gridColumnApi) {
          this.gridColumnApi.applyColumnState({
            state: [
              {
                colId: "actionEdit",
                hide: !editable,
              },
            ],
          });
        }
      },
      immediate: true,
    },
    multiSort: {
      handler() {
        //this.listState.options.multiSort = val;
      },
      immediate: true,
    },
    loading: {
      handler(val) {
        // set ag-grid in loading mode
        if (this.gridApi) {
          if (val) {
            this.gridApi.showLoadingOverlay();
          } else {
            this.gridApi.hideOverlay();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchData({
      startRow, // eg 0
      endRow, // eg 100
      sortModel,
      filterModel,
      successCallback,
      failCallback,
    }) {
      /**
       * Load paginated and sorted data list
       */
      var perPage = endRow - startRow;
      var page = startRow / perPage + 1;
      var params = { pagination: { page: page, perPage: perPage } };
      this.$log.debug("Load list from", `${this.resource}/getList`, params);
      //this.$log.debug("Query params", this.queryParams)
      var dispatch_call = this.$store.dispatch;
      // if (!this.disableCache && this.$store.cache) {
      //   dispatch_call = this.$store.cache.dispatch;
      //   this.$log.info("Use cache for", `${this.resource}/getList`);
      // }
      var sortMap = sortModel.map(function (s) {
        return { by: s.colId, desc: s.sort == "desc" };
      });
      params.sort = sortMap;
      // set filter
      var _filter = {};
      for (const col in filterModel) {
        var v = filterModel[col]["filter"];
        var t = filterModel[col]["type"];
        if (t === "inRange") {
          v = v + "," + filterModel[col]["filterTo"];
        }
        _filter[col] = {
          op: filterModel[col]["type"],
          value: v,
        };
      }
      params.filter = _filter;

      params.locale = this.$i18n.locale;
      this.$log.debug("Load list from", `${this.resource}/getList`, params);
      dispatch_call(`${this.resource}/getList`, params)
        .then(({ data, total }) => {
          var dataCopy = false;
          if (dataCopy) {
            const rowData = data; // get the data from our Vuex data store
            var out = Object.freeze(
              // reduce memory footprint - see above
              rowData.map((row) => {
                // copy to detach from the stores copy
                return {
                  ...row,
                };
              })
            );
          } else {
            out = data;
          }
          successCallback(out, total);
        })
        .then(failCallback);
    },
    cellValueChanged(event) {
      console.log("Cell value changed", event);
      var new_value = { id: event.data.id };
      new_value[event.colDef.field] = event.newValue;
      console.log("Update with the following data", new_value);
      this.$store
        .dispatch(`${this.resource}/update`, {
          id: new_value.id,
          data: new_value,
        })
        .then(() => {
          this.$admin.toast.success(this.$t("va.actions.saved"));
        })
        .catch(() => {
          this.$admin.toast.error(this.$t("va.actions.not_saved"));
        });
    },
    // TODO use gridApi methods, needs to be availabl inside of the event.
    // Functions to used to support copy and paste
    // COPY & CUT
    sendToClipboard(event) {
      var srcElement = document.activeElement;
      if (srcElement && "__agComponent" in srcElement) {
        event.preventDefault();
        var comp = srcElement.__agComponent;
        console.log("Copy to clipboard", comp);
        var gridApi = comp.rowNode.gridApi;
        var node = gridApi.getSelectedNodes();
        console.log(node);
        var text = comp.getValue();
        this.copyToClipboard(text);
        var rowNode = node[0];
        var colKey = comp.column.colId;
        gridApi.setFocusedCell(rowNode.rowIndex, colKey);
        // remove content if cut was used and in edit mode
        if (
          event.type == "cut" &&
          comp.column.colDef.editable &&
          !rowNode.gridOptionsWrapper.gridOptions.suppressClickEdit
        ) {
          console.log("Remove entry for ", colKey);
          rowNode.setDataValue(colKey, "");
          //var colId = comp.column.colId;
          //comp.rowNode.setDataValue(colId, "");
        }
      }
    },
    // PASTE
    fromClipboard(event) {
      var srcElement = document.activeElement;
      if (srcElement && "__agComponent" in srcElement) {
        var comp = srcElement.__agComponent;
        if (comp.column.colDef.editable) {
          event.preventDefault();
          var text = (event.clipboardData || window.clipboardData).getData(
            "text"
          );
          var colId = comp.column.colId;
          comp.rowNode.setDataValue(colId, text);
        }
      }
    },
    // Copies a string to the clipboard.
    // Adds a textfield which is removed afterwards
    copyToClipboard: function (text) {
      // TODO select active element afterwards again
      //var activeEle = document.activeElement;
      if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
          return document.execCommand("copy"); // Security exception may be thrown by some browsers.
        } catch (ex) {
          console.warn("Copy to clipboard failed.", ex);
          return false;
        } finally {
          document.body.removeChild(textarea);
        }
      }
      //activeEle.select();
    },
    getDefaultAlign(field) {
      if (["number"].includes(field.type)) {
        return "right";
      }
      return "left";
    },
    onRowClick(item) {
      switch (this.rowClick) {
        case "show":
          this.$router.push({
            name: `${this.resource}_show`,
            params: { id: item.id },
          });
          break;
        case "edit":
          this.$router.push({
            name: `${this.resource}_edit`,
            params: { id: item.id },
          });
          break;
        default:
          /**
           * Generic `row-click` event if you set rowClick on `true` with selected item.
           */
          this.$emit("row-click", item);
          break;
      }
    },
    async onAction(action, item) {
      if (action === "edit" && this.rowEdit) {
        this.createRowForm(item);
        return;
      }

      if (!this[`disable${upperFirst(action)}Redirect`]) {
        return;
      }

      let hasItem = action !== "create";

      let title = this.$admin
        .getResource(this.resource)
        .getTitle(action, hasItem ? item : null);
      let id = hasItem ? item.id : null;

      /**
       * Get freshed item
       */
      let { data } = await this.$store.dispatch(`${this.resource}/getOne`, {
        id: item.id,
      });
      this.$store.commit(`${this.resource}/setItem`, data);

      /**
       * Triggered on action on specific row.
       * This event will return a freshed item Object from your API.
       */
      this.$emit("item-action", { action, title, id, item: data });
    },
    showDetails(e) {
      this.$emit("show-details", e);
    },
    createRowForm(item = null) {
      this.editRowId = item ? item.id : null;

      this.form = this.getFields
        .map((f) => f.source)
        .reduce((o, source) => {
          return {
            ...o,
            [source]: item ? item[source] : null,
          };
        }, {});

      this.errors = {};
    },
    async save() {
      this.saving = true;

      try {
        this.editRowId
          ? await this.$store.dispatch(`${this.resource}/update`, {
              id: this.editRowId,
              data: { ...this.form, ...this.updateData },
            })
          : await this.$store.dispatch(`${this.resource}/create`, {
              data: { ...this.form, ...this.createData },
            });

        this.editRowId = null;
        this.form = null;
        this.errors = {};

        //this.reload();
      } catch (e) {
        if (e.errors) {
          this.errors = e.errors;
        }
      } finally {
        this.saving = false;
      }
    },
  },
  components: {
    AgGridField, // eslint-disable-line vue/no-unused-components
    AgGridInput, // eslint-disable-line vue/no-unused-components
    AgGridActions, // eslint-disable-line vue/no-unused-components
  },
};
</script>

<style>
.grid-progess-bar {
  transform: translateY(45px);
}
</style>

<style lang="scss">
// ag-grid theme overrides
// see: https://www.ag-grid.com/vue-grid/themes-customising/
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

.ag-theme-material {
  @include ag-theme-material(
    (
      grid-size: 6px,
      odd-row-background-color: rgba(0, 0, 0, 0.01),
      row-hover-color: #eee,
      header-column-separator: true,
      header-column-separator-height: 30%,
      header-column-separator-width: 1px,
      header-column-separator-color: #e2e2e2,
    )
  );
}
</style>
