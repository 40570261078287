import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createCache({
      timeout: 60000
    })
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
