<template>
  <div>
    <!--
        @slot Field placeholder. By default use field component according to `type` props with all other attributes merged.
      -->
    <slot v-bind="{ item: item, value }">
      <component
        ref="input"
        :is="`va-${type}-input`"
        :source="source"
        :resource="resource"
        :item="item"
        :value="value"
        v-bind="attrs"
        label=""
        @input="
          (val) => {
            value = val;
          }
        "
      ></component>
    </slot>
  </div>
</template>


<script>
//import Field from "../../../mixins/field";
import get from "lodash/get";

import Vue from "vue";
//import vuetify from '../../../../vuetify'

//Vue.use(vuetify)

export default Vue.extend({
  //mixins: [Field],
  name: "GridInput",
  //props: {
  //  /**
  //   * Override default label behavior.
  //   * Default is to get the localized VueI18n label from both resource and property source.
  //   */
  //  rowIndex: Number,
  //  /**
  //   * Override default source key as translated label.
  //   */
  //  prop: String,
  //  model: Object,
  //  column: Object,
  //  data: Array,
  //},
  data() {
    return {
      value: null,
      source: null,
      resource: null,
      type: null,
      item: null,
      attrs: null,
    };
  },
  created: function () {
    console.log("Editor open", this.params);
    this.value = this.params.value;
    this.source = get(this.params.colDef, "field", "");
    this.resource = get(this.params.colDef, "resource", "");
    this.type = get(this.params.colDef, "db_input", "text");
    this.item = this.params.data;
    this.attrs = get(this.params.colDef, "attributes", {});
    console.log("value: ", this.value);
    console.log("source: ", this.source);
    console.log("resource: ", this.resource);
    console.log("type: ", this.type);
    console.log("item: ", this.item);
    console.log("attrs: ", this.attrs);
  },
  computed: {
    //record() {
    //  return this.params.data;
    //},
    //source() {
    //  return get(this.params.colDef, "field", "");
    //},
    //resource() {
    //  return get(this.params.colDef, "resource", "");
    //},
    //type() {
    //  return get(this.params.colDef, "db_input", "text");
    //},
    //attrs() {
    //  return get(this.params.colDef, "attributes", "{}");
    //},
  },
  mounted() {
    //Vue.nextTick(() => this.$refs.input.focus());
  },
  methods: {
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
      // this simple editor doubles any value entered into the input
      console.log("Set new value: ", this.value);
      //if (Object.keys(this.value).includes("name")) {
      //  return this.value.name;
      //}
      return this.value;
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      // our editor will reject any value greater than 1000
      return false;
    },
  },
});
</script>

<style>
/*
.va-input .v-input__slot {
  display: block;
}
.va-input .v-label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
*/
</style>
