import {
  GET_LIST,
  GET_SCHEMA,
  GET_MANY,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "./actions";

import FetchJson from "../utils/fetchJson";
import qs from "qs";

export default (httpClient) => {
  if (typeof httpClient === "string") {
    httpClient = new FetchJson(httpClient);
  }

  return {
    [GET_LIST]: async (resource, params) => {
      const { pagination, sort, filter, locale, kwargs } = params;

      let query = {
      };

      if (filter) {
        query.filter = JSON.stringify(filter)
      }

      if (pagination) {
        let { page, perPage } = pagination;

        query = {
          ...query,
          per_page: perPage,
          page: page,
        };
      }

      if (sort && sort.length) {
        var sortMap = sort.map(function (s) {
          return [s.by, s.desc ? "desc" : "asc"]
        });
        query = {
          ...query,
          sort: JSON.stringify(sortMap),
        };
      }
      if (locale) {
        query.lang = locale
      }
      if (kwargs) {
        query = {
          ...query,
          ...kwargs
        }
      }
      //let { data, headers } = await httpClient.get(
      let { data } = await httpClient.get(
        `${resource}?${qs.stringify(query)}`
      );

      return {
        data: data.items,
        total: data._meta.total_items,
      };
    },
    [GET_SCHEMA]: async (resource, params) => {
      const { locale, theme } = params;

      let query = {
        lang: locale,
        theme: theme || "light"
      };

      let { data } = await httpClient.get(
        `${resource}/schema?${qs.stringify(query)}`
      );
      return data;
    },
    [GET_MANY]: (resource, params) =>
      httpClient.get(
        `${resource}?${qs.stringify({
          filter: JSON.stringify({ id: params.ids }),
        })}`
      ),
    [GET_ONE]: (resource, params) => httpClient.get(`${resource}/${params.id}?lang=${params.locale}&fallback_lang=de`),
    [CREATE]: (resource, params) => httpClient.post(`${resource}?lang=${params.locale}`, params.data),
    [UPDATE]: (resource, params) =>
      httpClient.put(`${resource}/${params.id}?lang=${params.locale}`, params.data),
    [UPDATE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => httpClient.put(`${resource}/${id}?lang=${params.locale}`, params.data))
      ).then(() => Promise.resolve()),
    [DELETE]: (resource, params) =>
      httpClient.delete(`${resource}/${params.id}`),
    [DELETE_MANY]: (resource, params) =>
      Promise.all(
        params.ids.map((id) => httpClient.delete(`${resource}/${id}`))
      ).then(() => Promise.resolve()),
  };
};
