<template>
  <va-translate-button
    :resource="listState.resource"
    @translate="onBulkTranslate"
  ></va-translate-button>
</template>

<script>
/**
 * Button for delete bulk actions for VaList. Shown after items selections.
 * Keep all VaDeleteButton feature and use `deleteMany` data provider method under the hood.
 */
export default {
  inject: {
    listState: { default: undefined },
  },
  props: {
    /**
     * Selected resources items.
     */
    value: Array,
  },
  computed: {
    currentResource() {
      return this.$admin.getResource(this.listState.resource);
    },
  },
  methods: {
    async onBulkTranslate() {
      let value = this.value || this.listState.selected;
        console.log(value)

        // UPDATE MANY, run translation
      
        //await this.$store.dispatch(`${this.listState.resource}/deleteMany`, {
        //  ids: value.map(({ id }) => id),
        //});

        /**
         * Cleanup selected elements
         */
        this.$emit("input", []);
        this.listState.selected = [];
        this.$store.dispatch("api/refresh", this.listState.resource);
    },
  },
};
</script>
