<template>
  <div>
      <!--
    <va-aside-layout :title="asideTitle">
      <users-show v-if="show" :item="item"></users-show>
      <users-form v-else :id="id" :item="item" @saved="onSaved"></users-form>
    </va-aside-layout>
        -->
    <base-material-card :icon="resource.icon" :title="title">
          <va-list
            disableGlobalSearch
            @action="onAction"
            :filters="fields"
          >
          <template v-slot="{ items }">
      <v-row justify="center" class="mx-2">
    <list-food-values :items="items" 
                      :groups="groups" 
                      :fields="fields"
                      class = "mt-4"/>
      </v-row>
          </template>
          </va-list>
    </base-material-card>
  </div>
</template>

<script>

import { guessFields } from "../../plugins/vuetify-admin/utils/guesserSchema";
import ListFoodValues from "@/components/foodvalues/ListFoodValues";
//import i18n from "../../i18n";
//import pick from "lodash/get";

export default {
  components: {
    ListFoodValues,
  },
  props: ["resource", "title"],
  data() {
    return {
      fields: [],
      groups: [],
      //items: [],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
      filters : [{
          source: "row_name",
          label : "Nahrungsmittel",
          filter : "text",
      },{
          source: "row_category",
          label : "Kategorie",
          filter : "text",
      }]
    };
  },
  async created() {
    this.fields = await guessFields(
      this.$store,
      this.$i18n,
      this.resource.name
    );

    // TODO add to admin config
    var disableCache = [1,"1","true",true].includes(process.env.VUE_APP_DISABLE_CACHE) || false

    var dispatch_call = this.$store.dispatch
    if (!disableCache && this.$store.cache) {
        dispatch_call = this.$store.cache.dispatch
    }
    var res = await dispatch_call("foodproperty_groups/getList", 
            { pagination: {perPage: 0}});
      this.groups = res.data
    //res = await dispatch_call("foodvalues/getList", 
            //{ pagination: {perPage: 50}});
      //this.items = res.data
  },
  methods: {
    //getItemsByGroup: function(group) {
    //    var name = this.groupName(group)
    //    return this.itemsByGroup.map(function(ele) {
    //        if (name in ele) {
    //            return ele[name]
    //        }
    //        return {}
    //    });
    //    //if (this.groupName(group) in this.itemsByGroup) {
    //    //    return this.itemsByGroup[this.groupNme(group)]
    //    //}
    //    //return {}
    //},
    //groupName: function(group) {
    //    return group["name_"+this.$i18n.locale]
    //},
    async onAction({ action, title, id, item }) {
      this.$log.warn("Called 'onAction' from 'resources/foodvalues/List.vue'")
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.$log.warn("Called 'onSaved' from 'resources/foodvalues/List.vue'")
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
  computed: {
    //itemsByGroup() {
    //    return this.getColumnItems.map((element) => {
    //        var new_dict = {}
    //        for (const [key, item] of Object.entries(element)) {
    //            if (!(item.column_group in new_dict)) {
    //                new_dict[item.column_group] = {}
    //            }
    //            new_dict[item.column_group][key] = item
    //        }
    //        return new_dict
    //        })
    //},  
    //getColumnItems: function() {
    //   console.log("getColumnsItems")
    //   console.log(this.items)
    //    if (this.items === undefined) {
    //        return []
    //    }
    //    return this.items.map((element) => {
    //        let { row_id, row_name, row_name_detail, row_category, // eslint-disable-line no-unused-vars 
    //             row_category_color_light, id, ...newEle } = element;// eslint-disable-line no-unused-vars
    //          return newEle;
    //        })
    //  },
  }
};
</script>
<style scoped>
.foodvalues-content {
    height: calc(100vh - 300px);;
    overflow-y: auto;
}
</style>
