<template>
  <a v-if="value" :href="link"
    :target="target">{{ value }}</a>
</template>

<script>
import Field from "../../../../mixins/field";

/**
 * Show value as simple anchor.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Target value for anchor, default to external.
     */
    target: {
      type: String,
      default: "_blank",
    },
  },
  data() {
    return {
      base_link: "https://www.ebi.ac.uk/ols/ontologies/foodon/terms?iri=http%3A%2F%2Fpurl.obolibrary.org%2Fobo%2FFOODON_"
    };
  },
  computed: { 
      link() {
          return this.base_link + this.value
      }
  },
};
</script>
