<template>
  <div>
  <v-expansion-panels :focusable="false">
          <v-expansion-panel
            v-for="(item, idx) in items"
            :key="item.id"
          >
          <v-expansion-panel-header>
            <div>
                <v-chip label small color="#e3e3e3" class="mr-4">
                <span style="color: #a4a4a4; font-family: monospace monospace; min-width:30px; text-align:center;">#{{item.row_id}}</span>
                </v-chip> 
                <span style="">{{item.row_name}}</span>
                <span class="text--disabled ml-3">{{item.row_name_detail}}</span>
            </div>
          </v-expansion-panel-header>
            <v-expansion-panel-content class="pa-0 foodvalues-content">

                <v-expansion-panels accordion :focusable="false" multiple flat tile>
                  <v-expansion-panel
                    v-for="group in groups" :key="group.id"
                  >
                  <v-expansion-panel-header v-if="Object.keys(getItemsByGroup(group)[idx]).length > 0"><b>{{groupName(group)}}</b></v-expansion-panel-header>
                    <v-expansion-panel-content class="foodvalues-group-content">
                        <food-values :item="getItemsByGroup(group)[idx]" :group="groupName(group)" :fields="fields"/>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
  </div>
</template>

<script>
import FoodValues from "./FoodValues";

export default {
  components: {
    FoodValues,
  },
  name: "ListFoodValues",
  props: {
    items: Array,
    fields: Array,
    groups: Array,
  },
  created() {
      //console.log("this.groups")
      //console.log(this.groups)
      //console.log("this.fields")
      //console.log(this.fields)
      //console.log("this.items")
      //console.log(this.items)
      //console.log("this.getColumnItems")
      //console.log(this.getColumnItems)
      //console.log('this.itemsByGroup')
      //console.log(this.itemsByGroup)
      //console.log('this.getItemsByGroup(Allgemein)')
      //console.log(this.getItemsByGroup(this.groups[0]))
  },

  methods: {
    getItemsByGroup: function(group) {
        var name = this.groupName(group)
        return this.itemsByGroup.map(function(ele) {
            if (name in ele) {
                return ele[name]
            }
            return {}
        });
        //if (this.groupName(group) in this.itemsByGroup) {
        //    return this.itemsByGroup[this.groupNme(group)]
        //}
        //return {}
    },
    groupName: function(group) {
        var name_source = "name_"+this.$i18n.locale
        if (group !== undefined && name_source in group) {
            return group[name_source]
        } else {
            return undefined
        }
    },
    //async onAction({ action, title, id, item }) {
    //  this.$log.warn("Called 'onAction' from 'resources/foodvalues/List.vue'")
    //  this.asideTitle = title;
    //  this.id = id;
    //  this.show = action === "show";
    //  this.item = item;
    //},
    //onSaved() {
    //  this.$log.warn("Called 'onSaved' from 'resources/foodvalues/List.vue'")
    //  this.asideTitle = null;
    //  this.$refs.list.fetchData();
    //},
  },
  computed: {
    itemsByGroup() {
        return this.getColumnItems.map((element) => {
            var new_dict = {}
            for (const [key, item] of Object.entries(element)) {
                if (!(item.column_group in new_dict)) {
                    new_dict[item.column_group] = {}
                }
                new_dict[item.column_group][key] = item
            }
            return new_dict
            })
    },  
    getColumnItems: function() {
       //console.log("getColumnsItems")
       //console.log(this.items)
        if (this.items === undefined) {
            return []
        }
        return this.items.map((element) => {
            let { row_id, row_name, row_name_detail, row_category, // eslint-disable-line no-unused-vars 
                 row_category_color_light, id, ...newEle } = element;// eslint-disable-line no-unused-vars
              return newEle;
            })
      },
  },
};
</script>
