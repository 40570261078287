<template>
  <!--
    Toggle sidebar.
    @event input
  -->

  <v-navigation-drawer
    :permanent="isPinned"
    :clipped="clippedVariant"
    app
    :dark="dark"
    :color="color"
    :mini-variant="isMini"
    :value="value"
    @input="
      (v) => {
        $emit('input', v);
        this.value = v;
      }
    "
  >
    <!-- Toggle and mini button 
  isDesktop: always pinned
  < isDesktip: possible to pin and toggle mini
  isPhone: possible to pin, but is always pinned as mini.
           Toggle mini witch menu button in toolbar (toggle-sidebar-left)
  -->
    <template v-slot:img="props">
      <!-- @slot Image background. -->
      <slot name="img" v-bind="props"></slot>
    </template>
    <!-- <div ref="sidebar" style="height: 400px; display: block; overflow: auto"> -->
    <!-- <overlay-scrollbars style="height: 400px; display: block; overflow: auto"> -->
    <v-list-item class="px-2" dense style="background-color: #00000070">
      <v-spacer v-if="!isPinned" />
      <v-btn
        :ripple="false"
        icon
        @click.stop="togglePinned"
        v-if="!isDesktop && ((!isPhone && !isMini) || !isPinned || isPhone)"
      >
        <v-icon v-if="pinned">mdi-pin-off</v-icon>
        <v-icon v-else>mdi-pin</v-icon>
      </v-btn>
      <v-spacer v-if="isPinned && !isPhone && !isMini" />
      <v-btn
        icon
        @click.stop="toggleMini"
        :ripple="false"
        v-if="isPinned && !isPhone"
      >
        <v-icon v-if="isMini">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <overlay-scrollbars
      style="height: calc(100% - 41px)"
      :options="{
        scrollbars: { autoHide: 'leave' },
        overflowBehavior: { x: 'hidden' },
      }"
    >
      <!-- User menu -->
      <v-list-item class="px-2" v-if="getUsername" two-line>
        <v-list-item-avatar>
          <v-img :src="get_gravatar_image_url(getEmail)"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <v-icon small class="mr-2" v-if="getMembership.icon">
              {{ getMembership.icon }}
            </v-icon>
            {{ getUsername }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="getEmail"> {{ getEmail }} </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <template v-for="(item, index) in menu.filter((l) => l)">
          <v-subheader v-if="item.heading && !miniVariant" :key="index">
            {{ item.heading }}
          </v-subheader>
          <v-divider v-else-if="item.divider" :key="index"></v-divider>
          <v-list-group
            v-else-if="item.children && item.children.length"
            :key="index"
            :value="item.expanded"
            :prepend-icon="item.icon"
            append-icon="mdi-chevron-down"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(child, i) in item.children"
              :key="i"
              link
              :to="child.link"
            >
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else-if="item.text" :key="index" link :to="item.link">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <!-- </div> -->
    </overlay-scrollbars>
  </v-navigation-drawer>
</template>

<script>
//import OverlayScrollbars from "overlayscrollbars";
/**
 * Default customizable admin VNavigationDrawer with full hierarchical menu and minimize variant.
 */
export default {
  props: {
    /**
     * Main place for side menu, support hierarchical structure, MDI icons, heading and dividers.
     */
    menu: {
      type: Array,
      default: () => [],
    },
    /**
     * Minimize the sidebar and show only icons.
     */
    // miniVariant: Boolean,
    /**
     * Main color of VNavigationDrawer.
     */
    color: {
      type: String,
      default: "white",
    },
    /**
     * Apply dark theme variant for VNavigationDrawer
     */
    dark: Boolean,
    /**
     * Control visibility
     */
    // value: null,
  },
  data() {
    return {
      pinned: false,
      mini: false,
      value: false,
    };
  },
  created: function () {
    this.$root.$on("toggle-sidebar-left", () => {
      this.toggleSidebar();
      // if (this.$vuetify.breakpoint.lgAndUp) {
      //   this.mini = !this.mini;
      // } else {
      //   this.drawer = !this.drawer;
      //   this.mini = false;
      // }
    });
  },
  mounted: function () {
    // var o = {
    //   className: "os-theme-dark",
    //   resize: "both",
    //   sizeAutoCapable: true,
    //   paddingAbsolute: true,
    //   scrollbars: {
    //     clickScrolling: true,
    //   },
    // };
    //this.$nextTick(() => {
    //  var inst = this.$refs.sidebar;
    //  var instance = OverlayScrollbars(inst.$el, {});
    //  console.log("OverlayScrollbr ready", instance);
    //  console.log("OverlayScrollbr inst", inst);
    //});
  },
  computed: {
    clippedVariant() {
      return this.isDesktop;
    },
    isPinned() {
      return this.pinned || this.isDesktop;
    },
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    isMini() {
      return this.mini;
    },
    getUsername() {
      return this.getUser("Name");
    },
    getEmail() {
      return this.getUser("Email");
    },
    getMembership() {
      return this.getUser("Membership");
    },
  },
  methods: {
    getUser(field) {
      return this.$store.getters["auth/get" + field];
    },
    toggleSidebar: function () {
      //this.$root.$emit("toggle-sidebar-left");
      if (this.isPinned) {
        this.toggleMini();
        this.value = false;
      } else {
        this.value = !this.value;
      }
    },
    toggleMini: function () {
      this.mini = !this.mini;
    },
    togglePinned: function () {
      this.pinned = !this.pinned;
      if (this.isPhone && this.pinned) {
        this.mini = true;
      } else {
        this.mini = false;
      }
    },
    get_gravatar_image_url: function (
      email,
      size,
      default_image,
      allowed_rating,
      force_default
    ) {
      var md5 = require("blueimp-md5");
      email = typeof email !== "undefined" ? email : "john.doe@example.com";
      size = size >= 1 && size <= 2048 ? size : 80;
      default_image =
        typeof default_image !== "undefined" ? default_image : "identicon";
      allowed_rating =
        typeof allowed_rating !== "undefined" ? allowed_rating : "g";
      force_default = force_default === true ? "y" : "n";

      return (
        "https://secure.gravatar.com/avatar/" +
        md5(email.toLowerCase().trim()) +
        "?size=" +
        size +
        "&default=" +
        encodeURIComponent(default_image) +
        "&rating=" +
        allowed_rating +
        (force_default === "y" ? "&forcedefault=" + force_default : "")
      );
    },
  },
};
</script>
