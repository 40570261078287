<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="0"
      transition="scale-transition"
      nudge-bottom="57"
      min-width="150px"
      max-width="300px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="input"
          v-bind="commonProps"
          readonly
          v-on="on"
          :filled="filled"
          @change="change"
          @input="update"
          @click:clear="input = null"
        ><template v-slot:append>
        <div :style="{'background-color' : input, 
                       'border-radius': '10px', 
                       width: '40px', 
                       height: '30px'}" />
        </template>
        </v-text-field>
      </template>
      <v-color-picker v-model="input" flat mode="hexa"
          @input="update"
      />
    </v-menu>
  </div>


<!--
<v-text-field v-model="input" hide-details class="ma-0 pa-0" >
					<template v-slot:append>
						<v-menu v-model="menu" top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
							<template v-slot:activator="{ on }">
								<div :style="swatchStyle" v-on="on" />
							</template>
							<v-card>
								<v-card-text class="pa-0">
									<v-color-picker v-model="input" flat mode="hexa" />
								</v-card-text>
							</v-card>
						</v-menu>
					</template>
				</v-text-field>
                -->

</template>

<script>
import Input from "../../../mixins/input";
import Editable from "../../../mixins/editable";

/**
 * Text editing for text value type via a basic text input.
 * Support textarea mode for long text via `multiline` prop.
 * Can be use for any date, datetime or time editing, use type set on `date`, `datetime-local` or `time`.
 * Render will depend of browser support.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Text to be edited.
     * @model
     */
    value: {
      type: String,
      default: "",
    },
    /**
     * Type of text input. All HTML type allowed.
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
		//color: '#1976D2FF',
		menu: false,
	}),
  computed: {
    swatchStyle() {
      const { input, menu } = this
      return {
        backgroundColor: input,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      }
    }
  }
};
</script>
