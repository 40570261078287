<template>
  <v-form ref="form" @submit.prevent="validate">

    <div class="btn-back-position">
      <v-btn @click="$router.push('login')"
          fab
          color="info"
        >
          <v-icon large>
            mdi-arrow-left
          </v-icon>
      </v-btn>
    </div>

    <div class="" style="height:60px; margin-top:25px;">
    <v-alert v-if="message" type="error">{{ message }}</v-alert>
    </div>

    <v-text-field
      :label="$t('auth.username')"
      prepend-icon="mdi-account"
      v-model="form.username"
      autocomplete="off"
      autocapitalize="off"
      required
      :error-messages="errorMessages.username"
    ></v-text-field>


    <v-text-field
      :label="$t('auth.email')"
      prepend-icon="mdi-email"
      v-model="form.email"
      autocomplete="email"
      autocapitalize="off"
      type="email"
      required
      :error-messages="errorMessages.email"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.password')"
      prepend-icon="mdi-lock"
      :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
      :type="show_password ? 'text' : 'password'"
      v-model="form.password"
      @click:append="show_password = !show_password"
      autocomplete="new-password"
      autocapitalize="off"
      required
      :error-messages="errorMessages.password"
    ></v-text-field>

    <v-text-field
      :label="$t('auth.password_confirmation')"
      prepend-icon="mdi-lock-question"
      :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show_password2 = !show_password2"
      :type="show_password2 ? 'text' : 'password'"
      v-model="form.password_confirmation"
      autocomplete="new-password"
      autocapitalize="off"
      required
    ></v-text-field>

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        type="submit"
        text
        rounded
      >
        {{ $t("auth.register") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
export default {
  props: {
    token: String,
  },
  data() {
    return {
      form: {
        username: null,
        email: null,
        password: null,
        password_confirmation: null,
      },
      errorMessages: {},
      loading: false,
      message: null,
      show_password: false,
      show_password2: false,
    };
  },
  methods: {
    async validate() {
      this.errorMessages = {};

      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
            await this.$admin.http.post("/users", this.form);

          this.$router.push("/");
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.message = e.message;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.btn-back-position {
    position: absolute;
    top: 60px;
    left: 35px;
    z-index: 1000;
}
</style>
