<template>
  <a :href="link" :target="target" v-if="value">{{ value }}</a>
</template>

<script>
import Field from "../../../../mixins/field";
import axios from "axios";

/**
 * Show value as simple anchor.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Target value for anchor, default to external.
     */
    target: {
      type: String,
      default: "_blank",
    },
  },
  data() {
    return {
      base_link: "https://naehrwertdaten.ch/de/search/#/food/",
      swiss_id_long: "",
    };
  },
  computed: {
    link() {
      return this.base_link + this.swiss_id_long;
    },
  },
  created() {
    if (this.value) {
      axios
        .get(
          "https://api.webapp.prod.blv.foodcase-services.com/BLV_WebApp_WS/webresources/BLV/fooddbid/" +
            this.value
        )
        .then((response) => {
          this.swiss_id_long = response.data;
        });
    }
  },
};
</script>
