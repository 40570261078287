var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height only-container"},[_c('va-create-layout',{staticStyle:{"padding-top":"150px"},attrs:{"title":_vm.title}},[_c('va-form',{attrs:{"item":_vm.item}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10","lg":"8"}},[_c('v-card',{attrs:{"elevation":_vm.$vuetify.breakpoint.mdAndUp ? 6 : 0,"shaped":"","color":_vm.$vuetify.breakpoint.mdAndUp ? '#eeff3' : undefined}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.inputs),function(input){return _c('v-col',{directives:[{name:"show",rawName:"v-show",value:(!input.hide),expression:"!input.hide"}],key:input.source,attrs:{"cols":input.attributes.multiline || input.type.includes('array')
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 6
                      : 12,"sm":input.attributes.multiline || input.type.includes('array')
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 4
                      : 6,"xl":input.attributes.multiline
                      ? 12
                      : ('number', 'color').includes(input.type)
                      ? 2
                      : input.type.includes('array')
                      ? 6
                      : 3}},[_c('div',[_c(("va-" + (input.input) + "-input"),_vm._b({tag:"component",attrs:{"filled":false,"label":input.label,"name":input.db_field,"source":input.source,"value":input.default}},'component',input.attributes,false))],1)])}),1),_c('va-save-button')],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }