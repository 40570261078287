<template>
  <div>
      <va-aside-layout :title="asideTitle">
          <component
          :is="`${details.resource}-${details.action}`"
          :resource="details.resource"
          :item="details.item"
          >
          </component>
      </va-aside-layout>
      <v-card>
        <v-card-title>
          <h1 class="display-1">
            {{ title }}
          </h1>
        </v-card-title>
        <v-card-text>
          <va-list
            disableGlobalSearch
            @action="onAction"
            :filters="fields"
          >
            <va-data-table 
                :fields="fields"
                :dense="true"
                @show-details="onAction"
            ></va-data-table>
          </va-list>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { guessFields } from "../../utils/guesserSchema";

export default {
  props: ["resource", "title"],
  data() {
    return {
      fields: [],
      asideTitle: null,
      details : {}
    };
  },
  async created() {
    this.fields = await guessFields(
      this.$store,
      this.$i18n,
      this.resource.name
    );

    if (this.$guessLogger) {
      this.$guessLogger(this.resource.name, "list", this.fields);
    }
  },
  methods: {
    async onAction({ action, title, id, item, reference }) {
      this.asideTitle = null;
      this.details.action = action;
      this.details.id = id;
      this.details.show = action === "show";
      this.details.item = item;
      this.details.resource = reference;
      this.asideTitle = title;
    },
  },
};
</script>
