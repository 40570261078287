<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style >
html {
  overflow-x: auto !important;
  overflow-y: auto !important;
}
</style>