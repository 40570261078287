<template>
  <span
    v-if="getText"
    :style="{ 'background-color': getColor, color: textColor }"
    class="category-field-style"
  >
    {{ getText }}
  </span>
</template>

<script>
import Field from "../../../mixins/field";
import get from "lodash/get";
//import Chip from "../../../mixins/chip";

/**
 * Show value inside a material chip.
 */
export default {
  mixins: [Field],
  props: {
    // source to icon (from item)
    "source-icon": { type: String, default: "row_category_icon" },
    // source to color (from item)
    "source-color": { type: String, default: "row_category_color" },
    // category name is taken from default 'source' prop
  },
  created: function () {
    //console.log("ITEM", this.item);
    //console.log("value", this.value);
    //console.log("source", this.source);
  },
  computed: {
    getColor() {
      const { item, sourceColor } = this;
      return get(item, sourceColor, "");
    },
    getIcon() {
      const { item, sourceIcon } = this;
      return get(item, sourceIcon, null);
    },
    getText() {
      return this.value || "";
    },
    textColor() {
      var hexcolor = this.getColor.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
  },
};
</script>

<style>
</style>
<style scoped>
.category-field-style {
  /* border: 1px solid #e0ac34; */
  padding: 4px;
}
</style>
