<template>
    <v-tooltip top open-delay=500 small
        :disabled="getRole == getName.toLowerCase() || ! tooltip">
      <template v-slot:activator="{ on, attrs }">
      <v-chip class="member-field-style" :class="contentClass" x-small label
                   v-bind="attrs"
                  v-on="on" 
          >
            <!-- @slot Chip content placeholder for further customization, show the value by default. -->
            <slot>
            <i aria-hidden="true" class="v-icon notranslate v-icon--left mdi theme--light" :class="getIcon" style="font-size: 12px;"></i>
            <!-- TODO
            <v-icon left x-small v-if="getIcon"> {{getIcon}} </v-icon>
            -->
            {{ getRole }}
            </slot>
          </v-chip>
      </template>
      <span>{{ getName }}</span>
    </v-tooltip>
</template>

<script>
import Field from "../../../mixins/field";
import get from "lodash/get";
//import Chip from "../../../mixins/chip";

/**
 * Show value inside a material chip.
 */
export default {
  mixins: [Field],
  props: {
    /**
     * Constraint image to full width instead of cover.
     * Ideal for logos.
     */
    tooltip: {type: Boolean, default: false},
    "content-class": {type: String, default: ""},
    // source to role is taken from default 'source' prop
    // source to icon (from item)
    "source-icon": {type: String, default: "icon"},
    // source to name (from item)
    "source-name": {type: String, default: "name"},
    // source to role is taken from default 'source' prop
  },
  computed: {
      getRole() {
          const { item, source } = this
          return get(item,source, "unknown");
      },
      getIcon() {
          const { item, sourceIcon } = this
          return get(item,sourceIcon, null);
      },
      getName() {
          const { item, sourceName } = this
          return get(item,sourceName, "");
      }
  }
};
</script>

<style>
.member-field-style {
    background-color: #ffc84b !important;
    padding: 9px 5px 8px 9px;
        border: 1px solid #e0ac34;
}
</style>
