<template>
  <span
    :style="{ 'background-color': value, color: textColor }"
    class="color-field-style no-truncate"
  >
    {{ getText }}
  </span>
</template>

<script>
import Field from "../../../mixins/field";

/**
 * Show value as simple text, render a simple span. HTML tags will be stripped.
 */
export default {
  mixins: [Field],
  props: {},
  computed: {
    getText() {
      if (this.value) {
        return this.value.toLowerCase();
      } else {
        return "#000000";
      }
    },
    textColor() {
      var hexcolor = this.getText.replace("#", "");
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? "black" : "white";
    },
  },
};
</script>
<style>
.color-field-style {
  padding: 8px;
  border-radius: 5px;
  font-size: xx-small;
  text-align: center !important;
  font-family: monospace, monospace;
}
</style>
