import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import i18n from "./i18n";
import admin from "./plugins/admin";
import logger from "./plugins/logger";
import "./plugins/i18n";
import "./plugins/base";
import "./plugins/chartist";
import "./sass/overrides.sass";

import "overlayscrollbars/css/OverlayScrollbars.css";

Vue.config.productionTip = true //false
Vue.config.performance = true


//import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsPlugin } from 'overlayscrollbars-vue';

Vue.use(OverlayScrollbarsPlugin);

import Vuebar from 'vuebar';
Vue.use(Vuebar);


new Vue({
  router,
  store,
  vuetify,
  i18n,
  admin,
  logger,
  render: h => h(App)
}).$mount('#app')
