<template>
  <va-show :item="item">
    <va-field source="username"></va-field>
    <va-field source="email"></va-field>
    <va-field source="membership.role" source-icon="membership.icon" type="member" tooltip></va-field>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
