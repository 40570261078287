<template>
  <v-app>
    <template>
      <!-- @slot Top app bar region, ideal place for VAppBar. -->
      <slot name="app-bar"></slot>
      <!-- @slot Sidebar region, ideal place for VNavigationDrawer. -->
      <slot name="sidebar"></slot>

      <v-main class="main">
        <div class="d-flex flex-column fill-height">
          <!-- @slot Content top header region, ideal place for VBreadcrumbs and/or additional custom messages or important notification as impersonation state, etc. -->
          <slot name="header"></slot>
          <va-messages></va-messages>

          <v-container
            fluid
            class="flex pa-0 pa-md-4 pt-md-3 pa-lg-7 pt-lg-4 pa-lg-auto pt-xl-8"
            style="max-width: 1880px"
          >
            <div class="background">
              <v-img
                height="100%"
                v-if="$vuetify.breakpoint.mdAndUp"
                src="@/assets/images/background-1-sm.jpg"
              >
              </v-img>
            </div>
            <!-- :outlined="!$vuetify.breakpoint.smAndDown" -->
            <!-- :tile="$vuetify.breakpoint.smAndDown" -->
            <v-card
              id="main-content-sheet"
              :elevation="$vuetify.breakpoint.mdAndUp ? 8 : 0"
              :class="$vuetify.breakpoint.mdAndUp ? 'rounded-lg' : 'rounded-0'"
              :height="$vuetify.breakpoint.height - toolbarOffsetBottom"
              style="overflow: hidden"
            >
              <!-- start GLOBAL TOOLBAR -->
              <!-- @slot Toolbar -->
              <slot name="toolbar">
                <v-app-bar
                  ref="globalToolbar"
                  dark
                  absolute
                  prominent
                  elevation="4"
                  style="z-index: 2"
                  :color="toolbarColor"
                  :shrink-on-scroll="toolbarShrink"
                  :fade-img-on-scroll="toolbarFadeImage"
                  :scroll-target="toolbarScrollTarget"
                >
                  <template v-slot:img>
                    <transition name="fade">
                      <v-img
                        v-if="getBackgroundImageSrc"
                        :src="getBackgroundImageSrc"
                        position="top 0 center"
                        height="100%"
                      />
                    </transition>
                  </template>
                  <v-app-bar-nav-icon
                    v-if="$vuetify.breakpoint.smAndDown"
                    @click.stop="$root.$emit('toggle-sidebar-left')"
                  />

                  <v-btn icon large v-else-if="toolbarIcon" class="transition">
                    <v-icon>{{ toolbarIcon }}</v-icon>
                  </v-btn>

                  <v-app-bar-title class="transition">
                    <v-icon
                      v-if="$vuetify.breakpoint.smAndDown && toolbarIcon"
                      >{{ toolbarIcon }}</v-icon
                    >
                    {{ toolbarTitle }}
                    <!-- </span> -->
                  </v-app-bar-title>

                  <v-spacer></v-spacer>

                  <div class="d-flex align-baseline">
                    <slot name="toolbar-actions"> </slot>
                  </div>
                </v-app-bar>
              </slot>
              <!-- end GLOBAL TOOLBAR -->

              <overlay-scrollbars
                id="scrollbar"
                style="overflow-y: auto; height: inherit"
                :options="{
                  scrollbars: { autoHide: 'leave' },
                  overflowBehavior: { x: 'hidden' },
                }"
              >
                <transition name="fade" mode="out-in">
                  <component
                    :is="'error'"
                    v-if="$store.state.messages.error"
                    :error="$store.state.messages.error"
                  ></component>
                  <!-- router-view -->
                  <router-view v-else />
                </transition>
              </overlay-scrollbars>
            </v-card>
          </v-container>

          <!-- @slot Footer region, put here some corporate information and links. -->
          <slot name="footer"></slot>
        </div>
      </v-main>

      <!-- @slot Right aside region, where you can put anything you need. -->
      <slot name="aside"></slot>
    </template>
  </v-app>
</template>

<script>
import Toolbar from "@/plugins/vuetify-admin/mixins/toolbar";

/**
 * Admin composable component layout with one slot for each region.
 */
export default {
  mixins: [Toolbar],
  props: { title: String, "toolbar-background-image": String },
  name: "Layout",
  data() {
    return {
      scrollListener: null,
      //globalToolbar: null,
    };
  },
  created() {},
  computed: {
    toolbarCalculateHeight() {
      return this.toolbarRef ? this.toolbarRef.computedHeight : 128;
    },
  },
  methods: {
    onScroll_setup: function (self, source, target) {
      var _this = self;
      var _src = source;
      var _t = target;
      // e = true if called directly and not by event: onScroll(true)
      return function (e) {
        if (e.target) {
          var test_text = e.target.attributes.ref
            ? e.target.attributes.ref.value
            : undefined;
          if (test_text == undefined) {
            test_text = e.target.attributes.id
              ? e.target.attributes.id.value
              : undefined;
          }
          if (test_text == undefined && e.target.attributes.class) {
            var _split = e.target.attributes.class.value.split(" ");
            test_text = _split[_split.length - 1]
              ? e.target.attributes.class.value
              : undefined;
            if (test_text.includes(_t.replace(".", ""))) {
              test_text = _t.replace(".", "");
            }
          }
        } else {
          test_text = undefined;
        }

        if (_t && (e === true || _t.includes(test_text))) {
          // needs to query every time, otherwise it does not work
          var _ct = document.querySelector(_t);
          if (_ct) {
            _this.$set(_src, "target", _ct);
            _src.onScroll(e);
          }
        }
      };
    },
  },
  watch: {
    toolbarScrollTargetUpdate: {
      handler() {
        var scrollTarget = this.toolbarScrollTarget;
        if (scrollTarget !== undefined && scrollTarget !== null) {
          if (this.scrollListener) {
            document.removeEventListener("scroll", this.scrollListener, true);
          }
          // new event listener is needed
          this.scrollListener = this.onScroll_setup(
            this,
            this.toolbarRef,
            scrollTarget
          );
          document.addEventListener("scroll", this.scrollListener, true);
          this.scrollListener(true);
        } else {
          document.removeEventListener("scroll", this.scrollListener, true);
        }
      },
      immediate: false,
    },
    toolbarCalculateHeight: {
      handler(height) {
        this.toolbarSetHeight(height);
      },
      immediate: true,
    },
  },
  destroyed() {
    // Remove events for scroll
    if (this.scrollListener) {
      document.removeEventListener("scroll", this.scrollListener, true);
    }
  },
};
</script>

<style>
.main {
  background-color: white;
  /* background: url(../../../../assets/images/background-1-sm.jpg) no-repeat
    center center fixed; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}
.background {
  /* z-index: -10 !important; */
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.fade-enter-active .transition,
.fade-leave-active .transition,
v-card-text .fade-enter-active,
v-card-text .fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter .transition,
.fade-leave .transition,
v-card-text .fade-enter,
v-card-text .fade-leave-to {
  opacity: 0;
}

/* Fix for title which is to short in prominent mode */
.v-app-bar-title__content {
  width: 100% !important;
}
/* .fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
} */
</style>
