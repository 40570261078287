<template>
  <span v-if="value !== null">
    <!--<v-progress-linear indeterminate v-if="loading"></v-progress-linear>-->
    <transition name="fade" mode="out-in">
      <div v-if="loading" style="max-width: 100px; min-height: 19px">
        <v-progress-linear
          indeterminate
          rounded
          color="primary"
          background-color="#00000012"
        ></v-progress-linear>
      </div>
      <v-chip
        v-else-if="chip"
        :color="getColor(value)"
        :small="small"
        :to="link"
      >
        <slot :value="value" :item="referenceItem">{{ getItemText }}</slot>
      </v-chip>
      <span v-else-if="aside && link">
        <slot :value="value" :item="referenceItem">
          <a @click="showDetails"> {{ getItemText }} </a>
        </slot>
      </span>
      <router-link v-else-if="link" :to="link">
        <slot :value="value" :item="referenceItem">{{ getItemText }}</slot>
      </router-link>
      <span v-else>
        <!-- @slot Content placeholder for further customization, guess the resource text by default. -->
        <slot :value="value" :item="referenceItem">{{ getItemText }}</slot>
      </span>
    </transition>
  </span>
</template>

<script>
import Field from "../../../mixins/field";
import Reference from "../../../mixins/reference";

/**
 * Display a reference link to another existing resource. Can auto fetch the the target resource from source ID if asked.
 */
export default {
  mixins: [Field, Reference],
  props: {
    /**
     * Show link as a chip.
     */
    chip: Boolean,
    /**
     * Allow resource auto fetching from source.
     */
    fetch: Boolean,
    aside: Boolean,
  },
  data: () => {
    return {
      loading: false,
      referenceItem: null,
    };
  },
  created: function () {},
  computed: {
    getId() {
      return this.referenceItem
        ? this.referenceItem[this.itemValue]
        : this.value;
    },
    link() {
      let resource = this.$admin.getResource(this.reference);

      if (resource.routes.includes(this.action)) {
        return {
          name: `${this.reference}_${this.action}`,
          params: {
            id: this.getId,
          },
        };
      }

      return null;
    },
    getItemText() {
      if (!this.referenceItem) {
        return this.value;
      }

      let resource = this.$admin.getResource(this.reference);
      let text = this.itemText || resource.label;

      if (typeof text === "function") {
        return text(this.referenceItem);
      }
      return this.referenceItem[text] !== undefined
        ? this.referenceItem[text]
        : this.referenceItem;
    },
    locale() {
      // used to check for updates
      return this.$store.state[this.resource].locale;
    },
  },
  watch: {
    value: {
      async handler(newVal) {
        var dispatch_call = this.$store.cache.dispatch;
        //var dispatch_call = this.$store.dispatch
        if (newVal === Object(newVal)) {
          /**
           * Full reference object is already loaded
           */
          this.referenceItem = newVal;
        }

        if (this.fetch && this.referece && newVal !== undefined) {
          this.loading = true;
          let { data } = await dispatch_call(`${this.reference}/getOne`, {
            id: newVal,
          });
          this.referenceItem = data;
          this.loading = false;
        }
      },
      immediate: true,
    },
    locale: {
      async handler(code) {
        //this.$store.dispatch(`${this.reference}/changeLocale`, code);
        var dispatch_call = this.$store.cache.dispatch;
        if (this.fetch && this.getId !== undefined) {
          this.loading = true;
          let { data } = await dispatch_call(`${this.reference}/getOne`, {
            id: this.getId,
            local: code,
          });
          this.referenceItem = data;
          this.loading = false;
        }
      },
      immediate: true,
    },
    //reload: {
    //  async handler() {
    //    console.log("Reload watch for ", this.reference)
    //    console.log(this.referenceItem)
    //    if (this.fetch && this.referenceItem) {
    //      this.loading = true;
    //      //var dispatch_call = this.$store.cache.dispatch
    //      var dispatch_call = this.$store.dispatch
    //      let { data } = await dispatch_call(
    //        `${this.reference}/getOne`,
    //        // TODO where to set locale, why not updated?
    //            //, lang: this.$i18n.locale }
    //        { id: this.referenceItem.id }
    //      );

    //      this.referenceItem = data;
    //      this.loading = false;
    //    }
    //  },
    //  immediate: true,
    //},
  },
  methods: {
    showDetails() {
      var action = "show";
      var title = this.getItemText;
      var id = this.value;
      var data = this.referenceItem;
      var reference = this.reference;
      var e = { action, title, id, item: data, reference };
      this.$emit("show-details", e);
    },
  },
};
</script>
