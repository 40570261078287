<template>
  <v-chip-group :column="column">
    <va-tag
      v-for="(item, i) in value"
      :key="i"
      :color="item.color"
      :label="getItemText(item)"
    >
    </va-tag>
    <!-- <v-chip
      class="tag-field-style"
      v-for="(item, i) in value"
      x-small
      label
      :key="i"
      :color="item.color"
    >
     -->
    <!-- @slot Default chip content slot, use simple text or select field by default. -->
    <!-- <slot :value="item">
        <span>
          {{ getItemText(item) }}
        </span>
      </slot>
    </v-chip> -->
  </v-chip-group>
</template>

<script>
import Field from "../../../mixins/field";
import Chip from "../../../mixins/chip";

/**
 * Show each single value of multiple array type value as material chip group.
 */
export default {
  mixins: [Field, Chip],
  props: {
    /**
     * Property used for stringify inner item if object.
     * Use a function for further stringify customization.
     */
    itemText: {
      type: [String, Array, Function],
      default: "name",
    },
    /**
     * Show list of chips as column.
     */
    column: Boolean,
  },
  methods: {
    getItemText(item) {
      if (typeof this.itemText === "function") {
        return this.item(item);
      }
      return item[this.itemText] || item;
    },
  },
};
</script>

<style scoped>
.tag-field-style {
  /* background-color: #ffc84b !important; */
  padding: 9px 5px 8px 5px;
  margin-right: 3px;
  /* border: 1px solid #ce2626c0; */
}
</style>
