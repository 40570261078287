<template>
  <div class="fill-height only-container">
    <va-create-layout :title="title" style="padding-top: 150px">
      <va-form :item="item">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
            <v-card
              :elevation="$vuetify.breakpoint.mdAndUp ? 6 : 0"
              shaped
              :color="$vuetify.breakpoint.mdAndUp ? '#eeff3' : undefined"
            >
              <v-card-text>
                <v-row>
                  <!-- ? 12 // multiline
                           : ....
                           ? 2 // color and numbers
                           : 3 // others -->
                  <v-col
                    v-for="input in inputs"
                    :key="input.source"
                    v-show="!input.hide"
                    :cols="
                      input.attributes.multiline || input.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 6
                        : 12
                    "
                    :sm="
                      input.attributes.multiline || input.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 4
                        : 6
                    "
                    :xl="
                      input.attributes.multiline
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 2
                        : input.type.includes('array')
                        ? 6
                        : 3
                    "
                  >
                    <div>
                      <component
                        :filled="false"
                        :label="input.label"
                        :name="input.db_field"
                        v-bind="input.attributes"
                        :source="input.source"
                        :is="`va-${input.input}-input`"
                        :value="input.default"
                      ></component>
                    </div>
                  </v-col>
                </v-row>

                <va-save-button></va-save-button>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </va-form>
    </va-create-layout>
  </div>
</template>

<!--
<template>
  <va-create-layout :title="title">
    <va-form :item="item">
      <v-row justify="center">
        <v-col sm="6">
          <v-card>
            <v-card-text>
              <div>
                <component
                  v-for="input in inputs"
                  :key="input.source"
                  :filled="false"
                  :label="input.label"
                  v-bind="input.attributes"
                  :source="input.source"
                  :is="`va-${input.input}-input`"
                  :value="input.default"
                ></component>
              </div>
              <va-save-button></va-save-button>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </va-form>
  </va-create-layout>
</template>
-->

<script>
import { guessCreateInputs } from "../../utils/guesserSchema";
import Toolbar from "@/plugins/vuetify-admin/mixins/toolbar";

export default {
  mixins: [Toolbar],
  props: ["title", "resource", "item"],
  data() {
    return {
      inputs: [],
    };
  },
  async created() {
    this.inputs = await guessCreateInputs(
      this.$store,
      this.$i18n,
      this.resource.name
    );
    var toolbarTitle = this.title;
    //if (this.inputs[0].field === "name") {
    //  toolbarTitle = `#${this.item.id} - ${this.item.name}`;
    //}
    this.toolbarUpdate({
      image: this.resource.background
        ? this.resource.background
        : "default.jpg",
      color: null,
      title: toolbarTitle,
      icon: this.resource.icon,
      shrink: true,
    });

    if (this.$guessLogger) {
      this.$guessLogger(this.resource.name, "create", this.inputs);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.toolbarUpdate({
        // update target when it is mounted
        scrollTarget: "#scrollbar .os-viewport", //#scrollbar",
      });
    });
  },
};
</script>
