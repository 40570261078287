<template>
  <div class="fill-height only-container">
    <va-edit-layout :title="title" style="padding-top: 150px">
      <va-form :id="id" :item="item">
        <v-row justify="center">
          <v-col cols="12" md="10" lg="8">
            <v-card
              :elevation="$vuetify.breakpoint.mdAndUp ? 6 : 0"
              shaped
              :color="$vuetify.breakpoint.mdAndUp ? '#eeff3' : undefined"
            >
              <v-card-text>
                <v-row>
                  <!-- ? 12 // multiline
                           : ....
                           ? 2 // color and numbers
                           : 3 // others -->
                  <v-col
                    v-for="input in inputs_computed"
                    :key="input.source"
                    v-show="!input.hide"
                    :cols="
                      input.attributes.multiline || input.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 6
                        : 12
                    "
                    :sm="
                      input.attributes.multiline || input.type.includes('array')
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 4
                        : 6
                    "
                    :xl="
                      input.attributes.multiline
                        ? 12
                        : ('number', 'color').includes(input.type)
                        ? 2
                        : input.type.includes('array')
                        ? 6
                        : 3
                    "
                  >
                    <div>
                      <component
                        :filled="false"
                        :label="input.label"
                        :name="input.db_field"
                        v-bind="input.attributes"
                        :source="input.source"
                        :is="`va-${input.input}-input`"
                      ></component>
                    </div>
                  </v-col>
                </v-row>

                <va-save-button></va-save-button>
                <!-- Created and modified fields -->
                <v-divider
                  inset
                  class="mt-2"
                  v-if="item && (item.created || item.modified)"
                />
                <div
                  style="font-size: smaller"
                  class="text--disabled text-right mt-2 mr-2"
                >
                  <va-datetime-by-field
                    v-if="item && item.created"
                    reference="users"
                    :item="item"
                    source="created_by"
                    item-text="username"
                    :fetch="true"
                    :label="$t('va.common.created_at')"
                    @show-details="showDetails"
                  ></va-datetime-by-field>
                  <br />
                  <va-datetime-by-field
                    v-if="
                      item && item.modified && item.created != item.modified
                    "
                    reference="users"
                    :item="item"
                    source="modified_by"
                    item-text="username"
                    :fetch="true"
                    :label="$t('va.common.modified_at')"
                    @show-details="showDetails"
                  ></va-datetime-by-field>
                </div>
                <!-- END Created and modified fields -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </va-form>
    </va-edit-layout>
  </div>
</template>

<script>
//import { guessInputs } from "../../utils/guesser";
import { guessInputs } from "../../utils/guesserSchema";
import Toolbar from "@/plugins/vuetify-admin/mixins/toolbar";

export default {
  mixins: [Toolbar],
  props: ["id", "title", "resource", "item"],
  data() {
    return {
      inputs: [],
      asideTitle: null,
      details: {},
    };
  },
  async created() {
    this.inputs = await guessInputs(
      this.$store,
      this.$i18n,
      this.resource.name
    );
    var toolbarTitle = this.title;
    if (this.inputs[0].field === "name") {
      toolbarTitle = `#${this.item.id} - ${this.item.name}`;
    }
    this.toolbarUpdate({
      image: this.resource.background
        ? this.resource.background
        : "default.jpg",
      color: null,
      title: toolbarTitle,
      icon: this.resource.icon,
      shrink: true,
    });

    if (this.$guessLogger) {
      this.$guessLogger(this.resource.name, "edit", this.inputs);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.toolbarUpdate({
        // update target when it is mounted
        scrollTarget: "#scrollbar .os-viewport", //#scrollbar",
      });
    });
  },
  computed: {
    inputs_computed: function () {
      return this.inputs.filter(
        (t) =>
          t && !(t.source.includes("created") || t.source.includes("modified"))
      );
    },
  },
  methods: {
    async showDetails({ action, title, id, item, reference }) {
      this.asideTitle = null;
      this.details.action = action;
      this.details.id = id;
      this.details.show = action === "show";
      this.details.item = item;
      this.details.resource = reference;
      this.asideTitle = title;
    },
  },
};
</script>
