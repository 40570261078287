import * as methods from "../providers/data/actions";


let storeActions = {};
let {
  GET_LIST,
  GET_SCHEMA,
  GET_TREE,
  GET_NODES,
  GET_ONE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
  MOVE_NODE,
} = methods;

export default ({ provider, resource, i18n, store }) => {
  let { name, api, getName } = resource;

  Object.values(methods).forEach(
    (action) => (storeActions[action] = async ({ state, commit, dispatch }, params) => {
      try {
        if (!provider) {
          throw new Error("No data provider defined");
        }

        if (!provider[action]) {
          throw new Error(`Data provider action '${action}' not implemented`);
        }

        // Clear cache if updated or new
        if ([UPDATE, UPDATE_MANY, CREATE, DELETE, DELETE_MANY].includes(action)) {
          for (var a of [GET_LIST, GET_ONE, GET_TREE, GET_NODES]) {
            var path = `${name}/${a}`;
            store.cache.clear(path);
          }
        }
        /**
         * Only set global loading when read actions
         */
        if ([GET_LIST, GET_SCHEMA, GET_TREE, GET_NODES, GET_ONE].includes(action)) {
          commit("api/setLoading", true, {
            root: true,
          });
        }

        params.locale = params.local || state.locale
        let response = await provider[action](api || name, {
          ...params,
        });

        commit("api/setLoading", false, {
          root: true,
        });

        /**
         * Apply success message on writes operations
         */
        dispatch("showSuccess", { action, params });
        return Promise.resolve(response);
      } catch (e) {
        commit("api/setLoading", false, {
          root: true,
        });
        dispatch("showError", e);
        dispatch("auth/checkError", e, {
          root: true,
        });
        return Promise.reject(e);
      }
    })
  );

  return {
    namespaced: true,
    state: {
      item: null,
      locale: i18n.locale,
      mode: { edit: false },
      //fields: null,
    },
    mutations: {

      setItem(state, item) {
        state.item = item;
      },

      setEditMode(state, value) {
        state.mode.edit = value;
      },
      //setFields(state, fields) {
      //  state.fields = fields;
      //},
      removeItem(state) {
        state.item = null;
        //state.refs = {}
      },
      setLocale(state, code) {
        state.locale = code;
      },
    },
    getters: {
      //getFields(state) {
      //  if (state.fields) {
      //    return state.fields;
      //  }
      //},
      getEditMode(state) {
        if (state.mode) {
          return state.mode.edit;
        }
        return false
      },
      getItem(state) {
        if (state.item) {
          return state.item;
        }
      },
    },
    actions: {
      ...storeActions,
      async refresh({ state, commit, dispatch }) {
        if (state.item) {
          /**
           * Refresh current resource and update item state
           */
          let { data } = await dispatch(GET_ONE, {
            id: state.item.id,
          });

          commit("setItem", data);
        }
      },
      changeLocale({ commit, dispatch }, code) {
        /**
         * Change locale and refresh
         */
        commit("setLocale", code);
        dispatch("refresh");
      },
      setEditMode({ commit }, value) {
        /**
         * Change locale and refresh
         */
        commit("setEditMode", value);
      },
      showSuccess({ commit }, { action, params }) {
        let messages = {
          [CREATE]: () =>
            i18n.t("va.messages.created", {
              resource: getName(1),
            }),
          [UPDATE]: () =>
            i18n.t("va.messages.updated", {
              resource: getName(1),
              id: params.id,
            }),
          [UPDATE_MANY]: () =>
            i18n.t("va.messages.updated_many", {
              resource: getName(params.ids.length).toLowerCase(),
              count: params.ids.length,
            }),
          [DELETE]: () =>
            i18n.t("va.messages.deleted", {
              resource: getName(1),
              id: params.id,
            }),
          [DELETE_MANY]: () =>
            i18n.t("va.messages.deleted_many", {
              resource: getName(params.ids.length).toLowerCase(),
              count: params.ids.length,
            }),
          [MOVE_NODE]: () =>
            i18n.t("va.messages.moved", {
              resource: getName(1),
              id: params.id,
            }),
        };

        if (messages[action]) {
          commit(
            "messages/showToast",
            { color: "primary", message: messages[action]() },
            {
              root: true,
            }
          );
        }
      },
      showError({ commit }, e) {
        var message = "error"
        if (e.response) {
          message = e.response.data.message
        } else if (e.message) {
          message = e.message
        } else {
          message = e
        }
        commit(
          "messages/showToast",
          { color: "error", message },
          {
            root: true,
          }
        );
      },
    },
  };
};
