import Vue from 'vue';

import VueLogger from 'vue-logger-plugin'

Vue.use(VueLogger);

// export logger with applied options
export default new VueLogger({
  enabled: true,
  level: 'debug',
  beforeHooks: [],
  afterHooks: []
});
