<template>
<div class="d-flex flex-row flex-wrap align-baseline">

          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
            <v-sheet
              class="d-inline-flex mr-2 justify-center align-center operator-menu-btn"
              color="white"
              elevation="2"
              height="30"
              width="30"
              v-bind="attrs"
              v-on="on"
              rounded
            >
            {{operator_symbol}}
            </v-sheet>
            </template>

            <v-list>
              <v-list-item
                v-for="(op, i) in operators"
                :key="i"
                @click="operator = op.operator;operator_symbol = op.symbol || op.operator;"
              >
              <v-list-item-title>{{ op.label }} ({{ op.symbol || op.operator }})</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>



  <!--
  v-bind="commonProps"
  
    @change="change"
  -->
  <v-text-field
    v-model="value_from"
    clearable
    :hideDetails="true"
    :filled="filled"
    type="number"
    :step="step"
    :min="min"
    :max="max"
  >
  <template v-slot:prepend>
           </template> 
  
  
  </v-text-field>
  <!--
  v-bind="commonProps"
    @change="change"
    @input="update"
  -->
  <!-- RANGE SELECTION -->
  <div
    class="mx-3"
    v-if="operator == 'between'"
    > 
        <v-sheet
              class="d-inline-flex justify-center align-center"
              color="white"
              elevation="1"
              height="20"
              width="20"
              rounded
            >
            &ndash;
            </v-sheet>
</div>

  <v-text-field
    v-if="operator == 'between'"
    v-model="value_to"
    type="number"
    clearable
    :hideDetails="true"
    :step="step"
    :min="min"
    :max="max"
  >
  </v-text-field>

  <v-btn @click="form_change" small color="primary" class="ml-4">
  apply
  </v-btn>
</div>
</template>

<script>
import Input from "../../../mixins/input";
import Editable from "../../../mixins/editable";
import debounce from "lodash/debounce";


/**
 * Optimized for number editing. Just a type number input with step, min and max attribute behavior.
 */
export default {
  mixins: [Input, Editable],
  props: {
    /**
     * Number to be edited.
     * @model
    value: {
      type: [Number, String],
      default: null,
    },
     */
    /**
     * Use full filled background color style.
     */
    filled: {
      type: Boolean,
      default: false,
    },
    /**
     * The interval step.
     */
    step: {
      type: Number,
      default: 1,
    },
    /**
     * The minimum accepted value.
     */
    min: Number,
    /**
     * The maximum accepted value.
     */
    max: Number,
  },
  data() {
    return {
        value_from: 0,
        value_to: 0,
        operator : "=",
        operator_symbol : "=",
        operators: [
            {operator: "=", label: "Equal"},
            {operator: "<", label: "Smaller"},
            {operator: ">", label: "Greater"},
            {operator: "!=", label: "Not Eual"},
            {operator: "between", symbol:"in", label: "Between range"},
            ]
    };
  },
  created: function() {
      //console.log("commonProps")
      //console.log(this.commonProps)
  },
  computed: {
      filter_value() {
          var value = this.value_from;
          if (this.operator == "between") {
              value = this.value_from+","+this.value_to;
          }
          return {"op" : this.operator, "value" :value}
      },
  },
  watch: {
   // // whenever question changes, this function will run
   // value_from: function () {
   //     this.form_change()
   // },
   // value_to: function () {
   //     this.form_change()
   // }
  },
  methods: {
    form_change: debounce(function () {
        console.log("FILTER UPDATE FROM")
        //var item = this.source
        //console.log(item)
        //console.log(value)
        console.log(this.filter_value)
        this.update(this.filter_value)
      }, 200)
    },

};
</script>
<style scope>
.operator-menu-btn {
    color: #6b6b6b !important;
    font-weight: bold;
}
</style>
