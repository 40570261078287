<template>
  <va-layout>
    <va-app-bar
      v-if="!$vuetify.breakpoint.smAndDown"
      slot="app-bar"
      :header-menu="headerMenu"
      :profile-menu="profileMenu"
      dense
      :dark="true"
    ></va-app-bar>
    <!-- v-model="drawer"
      :mini-variant="mini" -->
    <va-sidebar id="sidebar" slot="sidebar" dark :menu="sidebarMenu">
      <template v-slot:img="props">
        <v-img
          src="../assets/images/side-navigation-1-sm.jpg"
          gradient="to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)"
          v-bind="props"
        />
      </template>
    </va-sidebar>
    <template v-slot:toolbar-actions>
      <v-btn icon dark v-if="!$vuetify.breakpoint.xsOnly">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>

      <v-btn icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>

      <v-divider
        v-if="$vuetify.breakpoint.smAndUp"
        class="mx-2"
        vertical
        style="height: 28px; margin-top: 10px"
      ></v-divider>
      <!-- <v-btn icon v-if="editable">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn> -->
      <va-create-button
        v-if="editable"
        :disable-redirect="false"
        :resource="resource"
      ></va-create-button>
      <!-- @click="onAction('create')" -->
      <va-toggle-input
        v-model="editable"
        true-icon="mdi-pencil"
        false-icon="mdi-pencil-off"
      ></va-toggle-input>

      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <!--
    <template slot="header">
      <va-breadcrumbs></va-breadcrumbs>
      <impersonate-message></impersonate-message>
    </template>
    -->
    <va-aside slot="aside"></va-aside>

    <!--
    <va-footer slot="footer" :menu="footerMenu">
      &copy; 2020,
      <v-icon size="18">
        mdi-xml
      </v-icon>
      with
      <v-icon size="18">
        mdi-heart
      </v-icon>
      by
      <a href="https://www.company.com">My Awesome Company</a> for a better web.
    </va-footer>
    -->
  </va-layout>
</template>

<script>
//import ImpersonateMessage from "@/components/ImpersonateMessage";
import nav from "../_nav";
import Resource from "@/plugins/vuetify-admin/mixins/resource";

export default {
  mixins: [Resource],
  name: "App",
  components: {
    //ImpersonateMessage,
  },
  data() {
    return {
      editable: false,
      drawer: null,
      mini: false,
      headerMenu: [
        {
          link: "/",
          text: this.$t("menu.dashboard"),
        },
      ],
      footerMenu: [
        {
          href: "#",
          text: "About Us",
        },
        {
          href: "#",
          text: "Blog",
        },
        {
          href: "#",
          text: "License",
        },
      ],
      profileMenu: [
        {
          icon: "mdi-account",
          text: this.$t("menu.profile"),
          link: "/profile",
        },
      ],
      sidebarMenu: nav(this.$i18n, this.$admin),
    };
  },
  watch: {
    editable: {
      handler(val) {
        console.log("Edit mode change to:", val);
        this.$store.dispatch(`${this.resource}/setEditMode`, val);
        this.$store.commit("api/setEditMode", val);
      },
    },
  },
  created: function () {},
};
</script>

