import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
    locales: { en, de },
    current: process.env.VUE_APP_I18N_LOCALE || "de",
    },
    theme : {dark: false,
            themes: {light: {
              primary:   "#4caf50",
              secondary: "#009688",
              accent:    "#ff5722",
              error:     "#f44336",
              warning:   "#795548",
              info:      "#607d8b",
              success:   "#ffc107"
            },       dark: {
              primary:   "#4caf50",
              secondary: "#009688",
              accent:    "#ff5722",
              error:     "#f44336",
              warning:   "#795548",
              info:      "#607d8b",
              success:   "#ffc107"
            }
            }
    },
});
